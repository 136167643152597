import Cookies from "js-cookie";
import axios from "axios";
import {url} from "@/db/db";

export function getAllDirectories()
{
    return new Promise((resolve, reject) => {
        const urlGetAllDirectories = url + 'cloud/directories'

        const token = Cookies.get('token')

        axios.get(urlGetAllDirectories, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getAllDirectoriesInParent(parentName)
{
    return new Promise((resolve, reject) => {
        if (parentName === ".") {
            parentName = "none"
        }
        const urlGetAllDirectoriesInParent = url + 'cloud/directories/' + parentName
        const token = Cookies.get('token')

        axios.get(urlGetAllDirectoriesInParent, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getParentDirectoryFromDirectory(name)
{
    return new Promise((resolve, reject) => {
        if (name === ".") {
            name = "none"
        }
        const urlGetParentDirectoryFromDirectory = url + 'cloud/directory/parentDirectory/' + name

        console.log(urlGetParentDirectoryFromDirectory)
        console.log(name)
        const token = Cookies.get('token')

        axios.get(urlGetParentDirectoryFromDirectory, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function createNewDirectory(name, parent_name)
{
    return new Promise((resolve, reject) => {
        const urlGetAllDirectories = url + 'cloud/create-directory'

        const token = Cookies.get('token')
        console.log(parent_name)
        const newDirectory = {
            "name": name,
            "parent_name": parent_name === "Aucun" ? "" : parent_name
        }

        axios.post(urlGetAllDirectories, newDirectory, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function updateDirectoryName(name, newName)
{
    return new Promise((resolve, reject) => {
        const urlGetAllDirectories = url + 'cloud/directory'

        const token = Cookies.get('token')
        const data = {
            "name": name,
            "newName": newName
        }

        axios.put(urlGetAllDirectories, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function deleteDirectory(name)
{
    return new Promise((resolve, reject) => {
        const urlGetAllDirectories = url + 'cloud/directory/' + name

        const token = Cookies.get('token')

        axios.delete(urlGetAllDirectories, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getAllWodsInDirectory(name)
{
    return new Promise((resolve, reject) => {
        if (name === ".") {
            name = "none"
        }
        const urlGetAllWodsInDirectory = url + 'cloud/directory/' + name + '/wods';

        const token = Cookies.get('token')

        axios.get(urlGetAllWodsInDirectory, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getDirsInFavoriteList()
{
    return new Promise((resolve, reject) => {
        const urlGetDirsInFavoriteList = url + 'cloud/favorite-list';

        const token = Cookies.get('token')

        axios.get(urlGetDirsInFavoriteList, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function InsertDirInFavoriteList(dirId)
{
    return new Promise((resolve, reject) => {
        const urlInsertDirInFavoriteList = url + 'cloud/favorite-list';

        const token = Cookies.get('token')

        const data = {
            "dirId": dirId
        }

        axios.put(urlInsertDirInFavoriteList, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function DeleteDirInFavoriteList(dirId)
{
    return new Promise((resolve, reject) => {
        const urlInsertDirInFavoriteList = url + 'cloud/favorite-list';

        const token = Cookies.get('token')

        axios.delete(urlInsertDirInFavoriteList, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                dir_id: dirId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetDirectoryById(dirId)
{
    return new Promise((resolve, reject) => {
        const urlInsertDirInFavoriteList = url + 'cloud/directory';

        const token = Cookies.get('token')
        console.log(dirId);

        axios.get(urlInsertDirInFavoriteList, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                directoryId: dirId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}
