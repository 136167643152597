<template>
    <v-row justify="center" class="pa-4">
        <v-dialog v-model="dialog" persistent width="768">
            <template v-slot:activator="{ props }">
                <v-btn size="small" v-bind="props" :prepend-icon="logoButton" elevation="0">
                    {{ titleButton }}
                </v-btn>
            </template>
            <v-card>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" :style="{backgroundColor: my_green(), borderRadius: '10px'}">
                            <h2>{{ title }}</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field label="Nom*" required v-model="newName" variant="solo"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <h5>*Indique que le champs est requis</h5>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#2196f390" variant="elevated" @click="dialog = false">
                        Fermer
                    </v-btn>
                    <v-btn color="#F1C40F90" variant="elevated" v-on:click="valid">
                        Modifier
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>
import {my_green, my_purple} from "@/utils/enums";
import MySnackBar from "@/components/MySnackBar.vue";

export default {
    name: "ModifyDialog",
    components: {MySnackBar},
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        titleButton: {
            type: String,
            required: true
        },
        logoButton: {
            type: String,
            required: true
        },
        callback: {
            type: Function,
            required: false
        },
        openDefault: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        dialog: false,
        newName: ''
    }),
    created() {
      this.dialog = this.openDefault
    },
    methods: {
        my_green() {
            return my_green
        },
        my_purple() {
            return my_purple
        },
        valid() {
            if (this.callback && this.newName !== "") {
                this.dialog = false;
                this.callback(this.id, this.newName)
            } else {
                this.$refs.mySnackbarRef.active("Informations invalides", "error")
            }
        }
    }
}
</script>

<style scoped>
.my-title {
    font-family: 'Balsamiq', sans-serif;
    font-size: 32px;
    font-weight: bold;
}
</style>