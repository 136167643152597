<template>
    <v-row class="ma-0">
        <v-dialog v-model="eventDialog" width="50%" persistent>
            <v-progress-circular v-if="loading"></v-progress-circular>
            <v-card v-else>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                            <h2 style="padding: 5px">Ajouter un évènement</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <h2>Date d'ajout</h2><VueDatePicker style="margin-bottom: 10px; max-width: 30vw" v-model="this.event.date" :enable-time-picker="false" :format="format"/>
                        <h2 style="margin-bottom: 10px; margin-top: 30px">Titre</h2>
                        <v-row style="padding: 10px; border-radius: 10px">
                            <v-text-field
                                v-model="this.event.title"
                                single-line
                                density="compact"
                                variant="outlined"
                                label="Ajouter un titre"
                                :rules="this.rules"
                                style="min-width: 500px"
                            ></v-text-field>
                        </v-row>
                        <h2 style="margin-bottom: 10px; margin-top: 10px">Description</h2>
                        <v-row style="padding: 10px; border-radius: 10px">
                            <v-textarea
                                v-model="this.event.text"
                                auto-grow
                                single-line
                                density="compact"
                                variant="outlined"
                                label="Ajouter une description"
                                rows="2"
                                style="min-width: 500px"
                                hide-details
                                bg-color="#2196f390"
                            ></v-textarea>
                        </v-row>
                    </v-container>
                </v-card-text>
               <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#2196f390" variant="elevated" @click="this.eventDialog = false">
                    Fermer
                </v-btn>
                <v-btn color="#F1C40F90" variant="elevated" v-on:click="this.createNewEvent" @click="this.eventDialog = false">
                    Créer
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {mapState} from "vuex";
import MySnackBar from "@/components/MySnackBar.vue";
import {getDateWithBasicFormatDateParam} from "../utils/strings";
import {my_blue, my_red} from "@/utils/enums";
import VueDatePicker from "@vuepic/vue-datepicker";
import {insertNewEvent} from "@/db/events";

export default {
    name: "NewEventDialog",
    components: {VueDatePicker, MySnackBar},
    emits: ["refresh"],
    computed: {
        ...mapState(['user']),
    },
    watch: {
        eventDialog(newValue) {
            if (newValue) {
                this.event.date = this.date;
                console.log(this.event);
                this.loading = false;
            }
            else {
                this.reset();
            }
            console.log(newValue);
        },
    },
    props: {
        programId: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        eventDialog: false,
        loading: true,
        event: {
            title: "",
            date: new Date(),
            text: ""
        },
        rules: [v => v.length <= 25 || 'Max 25 caractères'],
        format: (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
    }),
    methods: {
        my_blue() {
            return my_blue
        },
        my_red() {
            return my_red
        },
        getDateWithBasicFormatDateParam,
        reset() {
            this.loading = true;
        },
        resetEvent() {
            this.event.title = "";
            this.event.date = new Date();
            this.event.text = "";
        },
        async createNewEvent()
        {
            const newEvent = {
                title: this.event.title,
                description: this.event.text,
                date: this.event.date
            }
            console.log(newEvent);
            try {
                await insertNewEvent(newEvent, this.programId);
                this.resetEvent();
                this.$refs.mySnackbarRef.active("Nouvel évènement crée", "success")
                this.$emit("refresh");
            } catch(err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
        },
    }
}
</script>

<style scoped>

</style>