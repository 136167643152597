import Cookies from "js-cookie";
import axios from "axios";
import {url} from "@/db/db";

export function UpdateProfile(newProfile)
{
    return new Promise((resolve, reject) => {
        const urlUpdateProfile = url + 'profile'

        const token = Cookies.get('token');

        axios.put(urlUpdateProfile, newProfile, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetProfile(profileId)
{
    return new Promise((resolve, reject) => {
        const urlUpdateProfile = url + 'profile'

        const token = Cookies.get('token');

        axios.get(urlUpdateProfile, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                profile_id: profileId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetPRs(profileId)
{
    return new Promise((resolve, reject) => {
        const urlGetPRs = url + 'profile/prs'

        const token = Cookies.get('token');

        axios.get(urlGetPRs, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                profile_id: profileId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetProfileWithSpecificString(pseudo)
{
    return new Promise((resolve, reject) => {
        const urlGetPRs = url + 'profile'

        const token = Cookies.get('token');

        axios.get(urlGetPRs, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                pseudo: pseudo
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetProfileWithSpecificStringAndBoxName(pseudo, boxName)
{
    return new Promise((resolve, reject) => {
        const urlGetPRs = url + 'profile'

        const token = Cookies.get('token');

        axios.get(urlGetPRs, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                pseudo: pseudo,
                boxName: boxName,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function InsertNewPR(newPR)
{
    return new Promise((resolve, reject) => {
        const urlInsertNewPR = url + 'profile/pr'

        const token = Cookies.get('token');

        axios.post(urlInsertNewPR, newPR, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetSpecificPrsByMovement(profileId, movement)
{
    return new Promise((resolve, reject) => {
        const urlGetPRsBySpecificMovement = url + 'profile/pr'

        const token = Cookies.get('token');

        axios.get(urlGetPRsBySpecificMovement, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                profile_id: profileId,
                movement: movement,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function RemovePR(prId)
{
    return new Promise((resolve, reject) => {
        const urlDeletePR = url + 'profile/pr'

        const token = Cookies.get('token');

        axios.delete(urlDeletePR, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                pr_id: prId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetPRsOnCurrentMonth(yearId, monthId, profileId)
{
    return new Promise((resolve, reject) => {
        const urlPRsOnCurrentMonth = url + 'profile/pr'

        const token = Cookies.get('token');

        axios.get(urlPRsOnCurrentMonth, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                profile_id: profileId,
                month_id: monthId,
                year_id: yearId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetProfileID()
{
    return new Promise((resolve, reject) => {
        const urlGetProfileID = url + 'profile/id'

        const token = Cookies.get('token');

        axios.get(urlGetProfileID, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetProfilesFromFavoriteUser()
{
    return new Promise((resolve, reject) => {
        const urlGetProfilesFromFavoriteUser = url + 'favorite_profiles'

        const token = Cookies.get('token');

        axios.get(urlGetProfilesFromFavoriteUser, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function CheckIfInMyFavoriteList(profileId)
{
    return new Promise((resolve, reject) => {
        const urlCheckIfInMyFavoriteList = url + 'favorite_profiles/inside'

        const token = Cookies.get('token');

        axios.get(urlCheckIfInMyFavoriteList, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                profile_id: profileId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function InsertNewFavoriteProfile(profileId)
{
    return new Promise((resolve, reject) => {
        const urlInsertNewFavoriteProfile = url + 'favorite_profile'

        const token = Cookies.get('token');
        const profile = {
            "profileId": profileId,
        }

        axios.post(urlInsertNewFavoriteProfile, profile, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function DeleteFavoriteProfile(userId)
{
    return new Promise((resolve, reject) => {
        const urlDeleteFavoriteProfile = url + 'favorite_profile'

        const token = Cookies.get('token');

        axios.delete(urlDeleteFavoriteProfile, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                user_id: userId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}