import Cookies from "js-cookie";
import axios from "axios";
import {url} from "@/db/db";

export function getAllMovements()
{
    return new Promise((resolve, reject) => {
        const urlGetAllMovements = url + 'wods/datas/movements'

        const token = Cookies.get('token')

        axios.get(urlGetAllMovements, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function insertNewWod(wod, programId)
{
    return new Promise((resolve, reject) => {
        const urlInsertNewWod = url + 'wods'

        const token = Cookies.get('token');
        console.log(wod)

        axios.post(urlInsertNewWod, wod, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                programId: programId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getAllWodsFromUser()
{
    return new Promise((resolve, reject) => {
        const urlAllWods = url + 'wods'

        const token = Cookies.get('token');

        axios.get(urlAllWods, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getWodById(wodId)
{
    return new Promise((resolve, reject) => {
        console.log(wodId)
        const urlWodId = url + 'wods'

        const token = Cookies.get('token');

        axios.get(urlWodId, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                wod_id: wodId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getWodsOnSpecificMonth(yearId, monthId, profileId)
{
    return new Promise((resolve, reject) => {
        console.log(yearId)
        console.log(monthId)
        const urlWodId = url + 'wods'

        const token = Cookies.get('token');

        axios.get(urlWodId, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                profile_id: profileId,
                month_id: monthId,
                year_id: yearId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getWodsWithLimit(limit)
{
    return new Promise((resolve, reject) => {
        console.log(limit)
        const urlWodId = url + 'wods'

        const token = Cookies.get('token');

        axios.get(urlWodId, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                limit: limit
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function deleteWod(wodId)
{
    return new Promise((resolve, reject) => {
        console.log(wodId)
        const urlDeleteWod = url + 'wods'

        const token = Cookies.get('token');

        axios.delete(urlDeleteWod, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                wod_id: wodId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function GetScoresFromWod(wodId)
{
    return new Promise((resolve, reject) => {
        const urlScore = url + 'wods'

        const token = Cookies.get('token');

        axios.get(urlScore, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                wod_id: wodId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function InsertNewWod(wodId, newScore)
{
    return new Promise((resolve, reject) => {
        const urlNewScore = url + 'wods'

        const token = Cookies.get('token');

        axios.post(urlNewScore, newScore, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                wod_id: wodId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function MoveWodInNewDirectory(newFolderName, wodId)
{
    return new Promise((resolve, reject) => {
        const urlGetAllDirectories = url + 'wods/move-wod'

        const token = Cookies.get('token')
        const data = {
            "newFolderName": newFolderName,
            "wodId": wodId,
        }

        axios.put(urlGetAllDirectories, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}