<template>
    <v-container fluid>
        <v-row justify="center" class="mb-5">
            <h1 class="h1-custom-size">{{ $t("registration_page") }}</h1>
        </v-row>
        <v-row v-if="alert">
            <v-alert :color="color" icon="$info" :title="message" max-width="600" class="mx-auto"></v-alert>
        </v-row>
        <v-row justify="center">
            <v-col :cols="12" :sm="10" :md="8" :lg="6">
                <v-card class="mx-auto px-6 py-8" max-width=600 elevation="3">
                    <v-form v-model="form" @submit.prevent="onSubmit">
                        <v-text-field v-model="surname" :readonly="loading" :rules="[required]" class="mb-2" clearable :label="$t('surname')"></v-text-field>
                        <v-text-field v-model="firstname" :readonly="loading" :rules="[required]" class="mb-2" clearable :label="$t('firstname')"></v-text-field>
                        <v-text-field v-model="age" :readonly="loading" :rules="[required]" class="mb-2" clearable :label="$t('age')"></v-text-field>
                        <v-text-field v-model="email" :readonly="loading" :rules="[required]" class="mb-2" clearable :label="$t('mail_address')"></v-text-field>
                        <v-text-field type="password" v-model="password" :readonly="loading" :rules="[required]" clearable :label="$t('password')" :placeholder="$t('enter_password')"></v-text-field>
                        <br>
                        <v-row justify="space-evenly">
                            <v-col class="flex-grow-0">
                                <v-btn :disabled="loading" block color="error" size="large" type="submit" variant="elevated" v-on:click="onCancel">
                                    {{ $t("return") }}
                                </v-btn>
                            </v-col>
                            <v-col class="flex-grow-0">
                                <v-btn :loading="loading" block color="info" size="large" type="submit" variant="elevated">
                                    {{ $t("button_registration") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import {createUser} from "@/db/connection";
import {GetProfileID} from "@/db/profile";

export default {
    name: "RegistrationView",
    methods: {
        onCancel() {
          this.$router.push('/login')
        },
        async onSubmit() {
            if (!this.form) {
                this.showAlert("missing_data", "error")
                return
            }
            if (this.password.length < 8) {
                this.showAlert("too_short_password", "error")
                return
            }
            if (!this.email.includes("@")) {
                this.showAlert("invalid_mail", "error")
                return
            }

            this.loading = true;
            try {
                this.showAlert("registration_in_progress", "warning")
                const response = await createUser(this.surname, this.firstname, this.age, this.email, this.password)
                this.showAlert("successful_registration", "success")
                const expirationDate = new Date()
                expirationDate.setDate(expirationDate.getDate() + 10)
                this.$cookies.set('token', response.token, expirationDate)

                const profileId = await GetProfileID();
                localStorage.setItem('profileId', profileId + '');
                this.$router.push('/')
            } catch (error) {
                console.log(error)
                /*if (error.response.data.error.includes("duplicate_entry") ) {
                    this.showAlert("mail_already_used", "error")
                } else {
                    this.showAlert("connection_error", "error")
                }*/
                this.showAlert("connection_error", "error")
            }
            this.loading = false;
        },
        required (v) {
            return !!v || this.$t("field_required")
        },

        showAlert(message, color) {
            this.color = color
            this.alert = true
            this.message = this.$t(message)
        }
    },
    data: () => ({
        form: false,
        surname: null,
        firstname: null,
        age: null,
        email: null,
        password: null,
        loading: false,
        alert: false,
        message: '',
        color: ''
    }),
}
</script>
