import {url} from "@/db/db";
import Cookies from "js-cookie";
import axios from "axios";

export function getAllEventsUser()
{
    return new Promise((resolve, reject) => {
        const urlGetAllEventsUser = url + 'events'

        const token = Cookies.get('token');

        axios.get(urlGetAllEventsUser, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getSpecificEventById(id, profileId)
{
    return new Promise((resolve, reject) => {
        const urlGetSpecificEventById = url + 'events/id'

        const token = Cookies.get('token');

        axios.get(urlGetSpecificEventById, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                event_id: id,
                profile_id: profileId,
            },
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function deleteEvent(id)
{
    return new Promise((resolve, reject) => {
        const urlDeleteEvent = url + 'events'

        const token = Cookies.get('token');

        axios.delete(urlDeleteEvent, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                'event_id': id
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function insertNewEvent(newEvent, programId)
{
    return new Promise((resolve, reject) => {
        const urlInsertNewEvent = url + 'events'

        const token = Cookies.get('token');

        axios.post(urlInsertNewEvent, newEvent, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                programId: programId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getEventsOnSpecificMonth(yearId, monthId, profileId)
{
    return new Promise((resolve, reject) => {
        console.log(yearId)
        console.log(monthId)
        const urlWodId = url + 'events/month'

        const token = Cookies.get('token');

        axios.get(urlWodId, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                profile_id: profileId,
                year_id: yearId,
                month_id: monthId
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}
