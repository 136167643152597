<template>
    <div class="text-center">
        <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
            <h4>{{ message }}</h4>
            <template v-slot:actions>
                <v-icon class="pr-4" icon="mdi-close-circle" @click="snackbar = false"></v-icon>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: "MySnackBar",
    data: () => ({
        snackbar: false,
        timeout: 3000,
        message: 'BONJOUR !',
        color: 'white',
    }),
    methods: {
        active(message, color) {
            this.message = message
            this.color = color
            this.snackbar = true
        }
    }
}
</script>

<style scoped>

</style>