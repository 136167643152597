export function errorSubstring(message) {
    const index = message.indexOf("=", message.indexOf("=") + 1)
    return message.substring(index + 1);
}

export function getDateWithBasicFormat(dateString)
{
    const [year, month, day, hour, minute, second] = dateString.split(/[- :]/);

    const date = new Date(year, month - 1, day, hour, minute, second);

    const formattedDay = date.getDate().toString().padStart(2, "0");
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    const formattedYear = date.getFullYear().toString();

    return `${formattedDay}/${formattedMonth}/${formattedYear}`;
}

export function getDateWithBasicFormatDateParam(date)
{
    return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
}

export function formatDate(date) {
    const options = { weekday: 'long', day: '2-digit', month: 'long' };
    return date.toLocaleDateString('fr-FR', options);
}

export function getDateWithBasicFormatWithoutDay(dateString)
{
    const [year, month, day, hour, minute, second] = dateString.split(/[- :]/);

    const date = new Date(year, month - 1, day, hour, minute, second);

    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
    const formattedYear = date.getFullYear().toString();

    return `${formattedMonth} ${formattedYear}`;
}

export function roundToDay(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function isEqualDate(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function ft_isDateBefore(date1, date2) {

    if (date1.getFullYear() < date2.getFullYear()) { return true;}
    if (date1.getFullYear() > date2.getFullYear()) { return false;}

    if (date1.getMonth() < date2.getMonth()) { return true }
    if (date1.getMonth() > date2.getMonth()) { return false }

    return date1.getDate() < date2.getDate();



}
export function getWeekOfMonth(date) {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDayOfWeek = firstDayOfMonth.getDay();
    const dayOfMonth = date.getDate();

    return Math.ceil((dayOfMonth + firstDayOfWeek) / 7) - 1;
}

export function getNextMondayFromDate(date) {
    const day = date.getDay(); // Récupération du jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
    const diff = 8 - day; // Calcul du nombre de jours restants jusqu'au prochain lundi
     // Ajout des jours pour obtenir le prochain lundi
    return new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);
}

export function getPrevMondayFromDate(date) {
    const day = date.getDay(); // Récupération du jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
    const diff = day === 1 ? 7 : day - 1; // Calcul du nombre de jours à soustraire pour obtenir le lundi précédent
     // Soustraction des jours pour obtenir le lundi précédent
    return new Date(date.getTime() - diff * 24 * 60 * 60 * 1000);
}

export function comparePositions(a, b) {
    return a.position - b.position;
}

export function fillDateArray(startDate, endDate, interval) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + interval);
    }

    return dateArray;
}

export function subtractDates(date1, date2) {
    let dateObj1 = new Date(date1);
    let dateObj2 = new Date(date2);

    if (ft_isDateBefore(dateObj2, new Date())) {
        return 0;
    }
    else if (ft_isDateBefore(new Date(), dateObj2) && ft_isDateBefore(dateObj1, new Date())) {
        dateObj1 = new Date();
    }

    const differenceMS = dateObj2 - dateObj1;

    const differenceJours = differenceMS / (1000 * 60 * 60 * 24);

    return differenceJours >= 0 ? Math.ceil(differenceJours) : 0;
}
