<template>
    <v-layout>
        <v-main>
            <v-container fluid>
                <v-row style="margin-right: 20px; margin-bottom: 0 ; align-items: center">
                    <v-btn style="margin-right: 10px; background-color: #2196f390; border-radius: 5px" variant="elevated" elevation="0" v-on:click="this.today">Aujourd'hui</v-btn>
                    <v-col cols="auto">
                        <v-icon class="chevrons-week-calendar" icon="mdi-chevron-left" v-on:click="this.prevWeek"></v-icon>
                    </v-col>
                    <v-col cols="auto">
                        <v-icon class="chevrons-week-calendar" icon="mdi-chevron-right" v-on:click="this.nextWeek"></v-icon>
                    </v-col>
                    <v-col v-if="isMe() === true" cols="auto">
                        <v-btn :style="{backgroundColor: '#34495E90'}" prepend-icon="mdi-plus-box" v-on:click="this.addEvent">Évènement</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <h3>{{ getBasicDateFormatWithoutDay(this.home.currentDate) }}</h3>
                </v-row>
                <v-container fluid>
                    <v-layout>
                        <v-col v-for="(card, colIndex) in 7" :key="colIndex" :class="this.getColumnClass(colIndex)">
                            <h3 style="background-color: #16A08590; text-align: center; border-radius: 10px">{{ dayOnWeek()[colIndex] }}</h3>
                            <h3 style="text-align: center; margin-top: 5px">{{ this.getDayOfMonth(colIndex).getDate() }}</h3>
                            <v-main style="overflow-y: auto; max-height: 9vh">
                                <div v-for="(wod, index) in this.getWodsOnSpecificDay(colIndex)" :key="index">
                                    <v-container style="padding: 1px 0 1px 10px; margin-bottom: 2px; background-color: #F1C40F90; border-radius: 10px">
                                        <h5 v-on:click="this.showWodDetails(wod)">WOD {{ wod.name }}</h5>
                                    </v-container>
                                </div>
                                <div v-for="(pr, index) in this.getPRsOnSpecificDay(colIndex)" :key="index">
                                    <v-container :style="{padding: '1px 0 1px 10px', marginBottom: '2px', backgroundColor: pr.color, borderRadius: '10px'}">
                                        <SpecificPRDialog :pr="pr" :active-by-button="false"></SpecificPRDialog>
                                    </v-container>
                                </div>
                                <div v-for="(event, index) in this.getEventOnSpecificDay(colIndex)" :key="index">
                                    <v-container :style="{padding: '1px 0 1px 10px', marginBottom: '2px', backgroundColor: '#34495E90', borderRadius: '10px'}">
                                        <EventDialog :event="event" @refresh="this.refresh"></EventDialog>
                                    </v-container>
                                </div>
                            </v-main>
                        </v-col>
                    </v-layout>
                </v-container>
            </v-container>
        </v-main>
    </v-layout>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
    <WodDialog ref="wodDialog"></WodDialog>
    <NewEventDialog ref="eventDialog" @refresh="this.refresh" :program-id="this.programId"></NewEventDialog>
</template>

<script>
import {dayOnWeek, getBasicDateFormatWithoutDay, my_blue, my_green, my_red, my_yellow} from "../utils/enums";
import {
    getDateWithBasicFormatWithoutDay,
    getNextMondayFromDate,
    getPrevMondayFromDate,
    isEqualDate
} from "../utils/strings";
import {getWodsOnSpecificMonth} from "@/db/wods";
import {mapMutations, mapState} from "vuex";
import {GetPRsOnCurrentMonth} from "@/db/profile";
import SpecificPRDialog from "@/components/SpecificPRDialog.vue";
import MySnackBar from "@/components/MySnackBar.vue";
import WodDialog from "@/components/WodDialog.vue";
import NewEventDialog from "@/components/NewEventDialog.vue";
import {getEventsOnSpecificMonth} from "@/db/events";
import EventDialog from "@/components/EventDialog.vue";
import {getAllEventsInProgram, getAllWodsInProgram} from "@/db/programs";

export default {
    name: "WeekCalendar",
    components: {WodDialog, NewEventDialog, EventDialog, MySnackBar, SpecificPRDialog},
    data: () => ({
        wodsOnCurrentMonth: [],
        prsOnCurrentMonth: [],
        eventsOnCurrentMonth: [],
        currentDate: getPrevMondayFromDate(new Date()),
        myProfileId: localStorage.getItem('profileId'),
    }),
    computed: {
        ...mapState(['home']),
    },
    props: {
        profileId: {
            type: String,
            required: true,
        },
        programId: {
            type: Number,
            required: true
        },
    },
    async created() {
        try {
            this.home.currentDate = getPrevMondayFromDate(new Date());
            console.log(this.home.currentDate)
            await this.fillWodsInCurrentMonth();
            if (this.programId === -1) {
                await this.fillPRsOnCurrentMonth();
            }
            await this.fillEventsOnCurrentMonth();
        } catch (err) {
            console.error(err)
        }
    },
    methods: {
        my_red() {
            return my_red
        },
        my_green() {
            return my_green
        },
        my_yellow() {
            return my_yellow
        },
        my_blue() {
            return my_blue
        },
        ...mapMutations(['setCurrentDate']),
        dayOnWeek() {
            return dayOnWeek
        },
        async fillWodsInCurrentMonth() {
            try {
                if (this.programId === -1) {
                    let wodsPreviousMonth = await getWodsOnSpecificMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth(), this.profileId);
                    let wodsCurrentMonth = await getWodsOnSpecificMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth() + 1, this.profileId);
                    let wodsNextMonth = await getWodsOnSpecificMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth() + 2, this.profileId);

                    if (!wodsPreviousMonth) {
                        wodsPreviousMonth = [];
                    }
                    if (!wodsNextMonth) {
                        wodsNextMonth = [];
                    }
                    if (!wodsCurrentMonth) {
                        wodsCurrentMonth = [];
                    }

                    this.wodsOnCurrentMonth = [...wodsPreviousMonth, ...wodsCurrentMonth, ...wodsNextMonth];
                } else {
                    this.wodsOnCurrentMonth = await getAllWodsInProgram(this.programId);
                }
                console.log(this.wodsOnCurrentMonth);
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        async fillEventsOnCurrentMonth() {
            try {
                if (this.programId === -1) {
                    let eventsPreviousMonth = await getEventsOnSpecificMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth(), this.profileId);
                    let eventsCurrentMonth = await getEventsOnSpecificMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth() + 1, this.profileId);
                    let eventsNextMonth = await getEventsOnSpecificMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth() + 2, this.profileId);

                    if (!eventsPreviousMonth) {
                        eventsPreviousMonth = [];
                    }
                    if (!eventsNextMonth) {
                        eventsNextMonth = [];
                    }
                    if (!eventsCurrentMonth) {
                        eventsCurrentMonth = [];
                    }

                    this.eventsOnCurrentMonth = [...eventsPreviousMonth, ...eventsCurrentMonth, ...eventsNextMonth];
                } else {
                    this.eventsOnCurrentMonth = await getAllEventsInProgram(this.programId);
                }
                console.log(this.eventsOnCurrentMonth);
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        async fillPRsOnCurrentMonth() {
            try {
                let prsPreviousMonth = await GetPRsOnCurrentMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth(), this.profileId);
                let prsCurrentMonth = await GetPRsOnCurrentMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth() + 1, this.profileId);
                let prsNextMonth = await GetPRsOnCurrentMonth(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth() + 2, this.profileId);

                if (!prsPreviousMonth) {
                    prsPreviousMonth = [];
                }
                if (!prsNextMonth) {
                    prsNextMonth = [];
                }
                if (!prsCurrentMonth) {
                    prsCurrentMonth = [];
                }

                this.prsOnCurrentMonth = [...prsPreviousMonth, ...prsCurrentMonth, ...prsNextMonth];
                console.log(this.prsOnCurrentMonth);
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        getBasicDateFormatWithoutDay, getDateWithBasicFormatWithoutDay,
        getDayOfMonth(colIndex) {
            const firstDayOfWeek = this.home.currentDate.getDate();
            const dayOfMonth = firstDayOfWeek + colIndex;

            const currentMonth = this.home.currentDate.getMonth();
            const currentYear = this.home.currentDate.getFullYear();
            const displayedDate = new Date(currentYear, currentMonth, 0);
            displayedDate.setDate(displayedDate.getDate() + dayOfMonth);
            return displayedDate;
        },
        checkIfIsToday(colIndex) {
            const firstDayOfWeek = this.home.currentDate.getDate();
            const dayOfMonth = firstDayOfWeek + colIndex;

            const currentMonth = this.home.currentDate.getMonth();
            const currentYear = this.home.currentDate.getFullYear();
            const displayedDate = new Date(currentYear, currentMonth, dayOfMonth);


            const today = new Date();
            return !!isEqualDate(displayedDate, today);

        },
        checkIfIsCurrentMonth(rowIndex, colIndex) {
            const firstDayOfMonth = new Date(this.home.currentDate.getFullYear(), this.home.currentDate.getMonth(), 0);
            const firstDayOfWeek = firstDayOfMonth.getDay(); // Jour de la semaine du premier jour du mois (0 pour dimanche, 1 pour lundi, etc.)
            const dayOfMonth = rowIndex * 7 + colIndex - firstDayOfWeek + 1; // Calcul du jour du mois en fonction de l'index de la ligne et de la colonne

            const currentMonth = this.home.currentDate.getMonth();
            const currentYear = this.home.currentDate.getFullYear();
            const displayedDate = new Date(currentYear, currentMonth, 0);
            displayedDate.setDate(displayedDate.getDate() + dayOfMonth);
            return displayedDate.getMonth() === currentMonth;

        },
        nextWeek() {
            this.home.currentDate = getNextMondayFromDate(this.home.currentDate);
            this.refresh();
        },
        prevWeek() {
            this.home.currentDate = getPrevMondayFromDate(this.home.currentDate);
            this.refresh();
        },
        today() {
            this.home.currentDate = getPrevMondayFromDate(new Date());
            this.refresh();
        },
        async refresh() {
            this.wodsOnCurrentMonth = [];
            this.prsOnCurrentMonth = [];
            this.eventsOnCurrentMonth = [];
            await this.fillWodsInCurrentMonth();
            if (this.programId === -1) {
                await this.fillPRsOnCurrentMonth();
            }
            await this.fillEventsOnCurrentMonth();
        },
        getWodsOnSpecificDay(colIndex) {
            const currentDay = this.getDayOfMonth(colIndex);
            // console.log(currentDay)
            if (!this.wodsOnCurrentMonth) {
                return [];
            }
            return this.wodsOnCurrentMonth.filter((wod) => {
                const wodDate = new Date(wod.date);
                return wodDate.getDate() === currentDay.getDate() && wodDate.getMonth() === currentDay.getMonth();
            })
        },
        getPRsOnSpecificDay(colIndex) {
            const currentDay = this.getDayOfMonth(colIndex);
            // console.log(currentDay)
            if (!this.prsOnCurrentMonth) {
                return [];
            }
            return this.prsOnCurrentMonth.filter((pr) => {
                const prDate = new Date(pr.dateCreate);
                return prDate.getDate() === currentDay.getDate() && prDate.getMonth() === currentDay.getMonth();
            })
        },
        getEventOnSpecificDay(colIndex) {
            const currentDay = this.getDayOfMonth(colIndex);
            if (!this.eventsOnCurrentMonth) {
                return [];
            }
            return this.eventsOnCurrentMonth.filter((event) => {
                const eventDate = new Date(event.date);
                return eventDate.getDate() === currentDay.getDate() && eventDate.getMonth() === currentDay.getMonth();
            })
        },
        getColumnClass(colIndex) {
            let style = "first-row-week my-col ";
            if (this.checkIfIsToday(colIndex)) {
                style += 'columns-calendar-week-today';
            } else {
                style += 'columns-calendar-week';
            }
            return style;
        },
        showWodDetails(wod) {
            console.log(wod);
            this.$refs.wodDialog.wodId = wod.id;
            this.$refs.wodDialog.wodDialog = true;
        },
        addEvent() {
            if (!this.$refs.wodDialog.wodDialog) {
                this.$refs.eventDialog.eventDialog = true;
            }
        },
        isMe() {
            return this.profileId === this.myProfileId;
        }
    },
}
</script>

<style>
.chevrons-week-calendar {
    border-radius: 5px;
    background-color: #2196f390;
}
.first-row-week {
    min-height: 20vh;
}
.columns-calendar-week {
    border: 1px solid #2196f340;
    background-color: #2196f315;
}
.columns-calendar-week-today {
    border: 1px solid #2196f360;
    background-color: #2196f380;
}
.rows-calendar-week {
    margin-left: 20px;
    margin-right: 20px
}
.my-col:hover {
    background-color: #2196f330;
}

</style>