<template>
    <v-row class="ma-0">
        <v-dialog v-model="dialog" width="1024">
            <template v-slot:activator="{ props }">
                <v-icon v-bind="props" style="border-radius: 5px" icon="mdi-plus-box"></v-icon>
            </template>
            <v-card>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                            <h2>Enregistrer un nouveau PR</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <h2 style="margin-bottom: 10px">Mouvement</h2>
                        <v-text-field label="Mouvement" v-model="newPR.movement"></v-text-field>
                        <v-menu v-model="menuOpen" :close-on-content-click="false">
                            <template v-slot:activator="{ props }">
                                <v-btn :color="my_green()" v-bind="props"><h3>PR existants</h3></v-btn>
                            </template>
                            <v-list class="scrollable-list">
                                <v-list-item v-for="item in filteredItems" :key="item" @click="selectItem(item)">
                                    <v-list-item-title>{{ item.movement }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <h2 style="margin-bottom: 10px; margin-top: 40px">Description / Charge</h2>
                        <v-text-field label="Description / Charge" v-model="newPR.description"></v-text-field>
                        <h2>Date de réalisation</h2><VueDatePicker style="margin-bottom: 20px; margin-top: 10px; max-width: 30vw" v-model="this.newPR.dateCreate" :enable-time-picker="false" :format="format"/>
                        <h2 style="margin-bottom: 10px; margin-top: 10px">Couleur</h2>
                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn variant="tonal" v-bind="props" :style="{backgroundColor: this.newPR.color}">Couleur de fond</v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(color, index) in colors" :key="index" style="margin: 0">
                                    <v-btn :style="{backgroundColor: color}" v-on:click="this.newPR.color = color">{{index + 1 === 1 ? 'Aucune' : index + 1}}</v-btn>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#2196f390" variant="elevated" @click="dialog = false">
                        Fermer
                    </v-btn>
                    <v-btn color="#F1C40F90" variant="elevated" @click="dialog = false" v-on:click="sendDataToParent">
                        Ajouter
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import {my_blue, my_green, my_orange, my_purple, my_red, my_yellow} from "@/utils/enums";
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
    name: "NewPRDialog",
    components: {VueDatePicker},
    emits: ["addNewPR"],
    props: {
        suggestedMovements: {
            type: Array,
            required: true,
        }
    },
    methods: {
        my_green() {
            return my_green
        },
        my_yellow() {
            return my_yellow
        },
        sendDataToParent() {
            this.$emit("addNewPR", this.newPR)
            this.newPR.movement = '';
            this.newPR.description = '';
            this.newPR.color = '';
            this.newPR.dateCreate = new Date();
        }
    },
    computed: {
        filteredItems() {
            return this.suggestedMovements.filter(item => item.movement.toLowerCase().includes(this.searchText.toLowerCase()));
        }
    },
    data: () => ({
        dialog: false,
        newPR: {
            movement: '',
            description: '',
            color: '',
            dateCreate: new Date()
        },
        menuOpen: false,
        searchText: '',
        format: (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
        colors: ["", my_yellow, my_blue, my_purple, my_green, my_red, my_orange],
        selectItem(item) {
            this.newPR.movement = item.movement;
            this.newPR.color = item.color;
            this.menuOpen = false;
        }
    }),
}
</script>

<style>
.scrollable-list {
    max-height: 250px;
    overflow-y: auto;
}
</style>