export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour tout le monde"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookfit"])},
  "button_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "button_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "mail_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer votre mot de passe"])},
  "login_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "registration_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
  "field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs requis"])},
  "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode sombre"])},
  "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "connection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion en cours"])},
  "successful_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion réussie"])},
  "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion"])},
  "missing_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données manquantes"])},
  "registration_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription en cours"])},
  "successful_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription réussie"])},
  "registration_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'inscription"])},
  "mail_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse mail est déjà utilisée"])},
  "too_short_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins 8 caractères"])},
  "invalid_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse mail est invalide"])}
}