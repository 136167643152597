import { createRouter, createWebHistory } from 'vue-router'
import RegistrationView from "@/views/RegistrationView.vue";
import Cookies from 'js-cookie'
import ConnexionView from "@/views/ConnexionView.vue";
import axios from "axios";
import HomeView from "@/views/HomeView.vue";
import {url} from "@/db/db";
import WodView from "@/views/WodView.vue";
import CloudView from "@/views/CloudView.vue";
import store from "@/store";
import MyCalendar from "@/components/MyCalendar.vue";
import ProfileView from "@/views/ProfileView.vue";
import ProgrammationView from "@/views/ProgrammationView.vue";
import NewProgramView from "@/views/NewProgramView.vue";


const routes = [
  {
    path: '/login',
    name: 'login',
    component: ConnexionView
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/wods/:dirId',
    name: 'wods',
    component: CloudView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const previousCloud = store.state.cloud;

      if (previousCloud) {
        store.commit('setCloud', previousCloud);
      }
      console.log(previousCloud)
      next()
    },
  },
  {
    path: '/wod/:id',
    name: 'wod',
    component: WodView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calendrier',
    name: 'Calendar',
    component: MyCalendar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/:profileId',
    name: 'Profile',
    component: ProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/programmation',
    name: 'Programmation',
    component: ProgrammationView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-program',
    name: 'NewProgramView',
    component: NewProgramView,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export function checkAuth() {
  return new Promise((resolve, reject) => {
    const token = Cookies.get('token')
    const urlCheckAccess = url + 'protected'
    axios.get(urlCheckAccess, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
  });
}
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    checkAuth().then(() => {
      next();
    }).catch(() => {
      next('/login');
    });
  } else {
    next();
  }
});

export default router
