<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="1024">
            <template v-slot:activator="{ props }">
                <v-icon size="20" icon="mdi-pencil" v-bind="props"></v-icon>
            </template>
            <v-card>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                            <h2>Modification du profil</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <h2 style="margin-bottom: 10px">Pseudo</h2>
                        <v-text-field label="Nouveau pseudo" v-model="newProfile.pseudo"></v-text-field>
                        <h2 style="margin-bottom: 10px">Nom de Box</h2>
                        <v-text-field label="Nom de la box où tu t'entraines" v-model="newProfile.boxName"></v-text-field>
                        <h2 style="margin-bottom: 10px; margin-top: 10px">Description</h2>
                        <v-textarea auto-grow label="Nouvelle description" v-model="newProfile.description"></v-textarea>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#2196f390" variant="elevated" @click="dialog = false">
                        Fermer
                    </v-btn>
                    <v-btn color="#F1C40F90" variant="elevated" @click="dialog = false" v-on:click="sendDataToParent">
                        Modifier
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: "EditProfile",
    emits: ["changeDataProfile"],
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        dialog: false,
        newProfile: {
            pseudo: "",
            description: "",
            boxName: ""
        }
    }),
    watch: {
        dialog(newValue) {
            if (newValue) {
                this.handleDialogOpen()
            }
        }
    },
    methods: {
        sendDataToParent() {
            this.$emit("changeDataProfile", this.newProfile);
        },
        handleDialogOpen() {
            console.log(this.profile)
            this.newProfile.pseudo = this.profile.pseudo;
            this.newProfile.description = this.profile.description;
            this.newProfile.boxName = this.profile.boxName;
        }
    }
}
</script>

<style scoped>

</style>