<template>
    <v-row class="ma-0">
        <v-dialog v-model="moveDialog" width="1024" persistent>
            <v-card>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                            <h2>Déplacer le WOD : {{ this.wod.name }}</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-subtitle>Dossier actuel : {{ this.path }}</v-card-subtitle>
                <v-card-text>
                    <SearchFolder @sendSelectedFolder="refreshSelectedFolder"></SearchFolder>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#2196f390" variant="elevated" @click="moveDialog = false">
                        Fermer
                    </v-btn>
                    <v-btn color="#16A08590" variant="elevated" v-on:click="this.moveFolder">
                        Valider
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {mapState} from "vuex";
import {my_green, my_purple} from "@/utils/enums";
import {getAllDirectories} from "@/db/cloud";
import {MoveWodInNewDirectory} from "@/db/wods";
import MySnackBar from "@/components/MySnackBar.vue";
import SearchFolder from "@/components/SearchFolder.vue";

export default {
    name: "MoveWodDialog",
    components: {SearchFolder, MySnackBar},
    emits: ["refresh"],
    computed: {
        ...mapState(['user']),
    },
    props: {
        wod: {
            type: Object,
            required: true,
        },
        path: {
            type: String,
            required: true,
        }
    },
    watch: {
        async moveDialog(newValue) {
            if (newValue) {
                this.folders = await getAllDirectories();
                this.childFolders = this.folders;
            }
        },
    },
    data: () => ({
        moveDialog: false,
        selectedFolder: {
            name: "",
            parent_name: "."
        },
        folders: [],
        childFolders: []
    }),
    methods: {
        my_green() {
            return my_green
        },
        my_purple() {
            return my_purple
        },
        closeDialog() {
            this.moveDialog = false;
        },
        async moveFolder() {
            try {
                if (this.selectedFolder.name === "Aucun Dossier") {
                    this.selectedFolder.name = '.';
                }
                await MoveWodInNewDirectory(this.selectedFolder.name, this.wod.id)
                this.$emit("refresh");
                this.moveDialog = false;
            } catch (err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion ou données invalides", "error");
            }
        },
        refreshSelectedFolder(value) {
            this.selectedFolder = value;
            console.log(this.selectedFolder);
        },
        handleSelected() {
            console.log(this.selectedFolder.name);
            this.childFolders = this.folders.filter(folder => folder.name.toLowerCase().includes(this.selectedFolder.name.toLowerCase()))
        },
    }
}
</script>

<style scoped>

</style>