<template>
    <v-container>
        <h2 style="margin-bottom: 10px">Dossiers</h2>
        <v-menu v-model="menuSearchFolder" :close-on-content-click="false">
            <template v-slot:activator="{ props }">
                <v-text-field
                    v-bind="props"
                    density="compact"
                    hide-details
                    single-line
                    label="Rechercher un dossier"
                    v-model="this.selectedFolder.name"
                    @input="this.handleSelected"
                ></v-text-field>
            </template>
            <v-list class="scrollable-list">
                <v-list-item v-for="folder in this.childFolders" :key="folder" v-on:click="selectFolder(folder)">
                    <template v-slot:prepend>
                        <v-icon size="25" icon="mdi-folder" style="margin-right: 10px"></v-icon>
                    </template>
                    <v-list-item-title v-if="folder.name !== '.'">{{ folder.name }}</v-list-item-title>
                    <v-list-item-title v-else>Aucun Dossier</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-container>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {getAllDirectories} from "@/db/cloud";
import MySnackBar from "@/components/MySnackBar.vue";

export default {
    name: "SearchFolder",
    components: {MySnackBar},
    data: () => ({
        menuSearchFolder: false,
        selectedFolder: {
            name: "",
            parent_name: "."
        },
        folders: [],
        childFolders: []
    }),
    async created() {
        this.folders = await getAllDirectories();
        this.childFolders = this.folders.filter(folder => folder.name.toLowerCase().includes(this.selectedFolder.name.toLowerCase()))
    },
    methods: {
        selectFolder(folder) {
            this.selectedFolder = folder;
            if (folder.name === '.') {
                this.selectedFolder.name = "Aucun Dossier"
            }

            this.$emit('sendSelectedFolder', this.selectedFolder);
        },
        handleSelected() {
            console.log(this.selectedFolder.name);
            console.log(this.childFolders);
        },
    }
}
</script>


<style>

</style>