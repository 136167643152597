import {url} from "@/db/db";
import Cookies from "js-cookie";
import axios from "axios";

export function getAllPrograms()
{
    return new Promise((resolve, reject) => {
        const urlGetAllPrograms = url + 'programs'

        const token = Cookies.get('token')

        axios.get(urlGetAllPrograms, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getWodsWithLimitInProgram(programId, limit)
{
    return new Promise((resolve, reject) => {
        const urlGetWods = url + 'program/wods_limit'

        const token = Cookies.get('token');

        axios.get(urlGetWods, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                program_id: programId,
                limit: limit
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function getNextWodsInProgram(programId)
{
    return new Promise((resolve, reject) => {
        const urlGetWods = url + 'program/next_wods'

        const token = Cookies.get('token');

        axios.get(urlGetWods, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                program_id: programId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function getAllWodsInProgram(programId)
{
    return new Promise((resolve, reject) => {
        const urlGetWods = url + 'program/wods'

        const token = Cookies.get('token');

        axios.get(urlGetWods, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                program_id: programId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getAllEventsInProgram(programId)
{
    return new Promise((resolve, reject) => {
        const urlGetEvents = url + 'program/events'

        const token = Cookies.get('token');

        axios.get(urlGetEvents, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                program_id: programId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function deleteProgram(programId)
{
    return new Promise((resolve, reject) => {
        const urlDeleteProgram= url + 'program'

        const token = Cookies.get('token');

        axios.delete(urlDeleteProgram, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                program_id: programId,
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function addNewProgram(newProgram)
{
    return new Promise((resolve, reject) => {
        const urlAddNewProgram= url + 'program'

        const token = Cookies.get('token');

        axios.post(urlAddNewProgram, newProgram, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}
