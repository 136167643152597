<template>
    <v-dialog persistent v-model="this.dialogVisible">
    <v-container fluid>
        <v-card max-width="448" class="mx-auto">
            <v-layout style="padding: 15px">
                <v-main>
                    <v-container fluid>
                        <div style="margin-top: 35px; margin-bottom: 35px">
                            <h3>Date de realisation : </h3>
                            <VueDatePicker style="margin-top: 10px;" v-model="this.search.dates" multi-dates multi-dates-limit="10" :enable-time-picker="false"/>
                        </div>
                        <div>
                            <h3>TYPE DE WOD</h3>
                            <v-card style="margin-bottom: 20px; elevation: 0">
                                <v-checkbox :model-value="this.includeSearchWodType('AMRAP')" hide-details label="AMRAP" v-on:click="this.handleCheckbox('AMRAP')"></v-checkbox>
                                <v-checkbox :model-value="this.includeSearchWodType('EMOM')" hide-details label="EMOM" v-on:click="this.handleCheckbox('EMOM')"></v-checkbox>
                                <v-checkbox :model-value="this.includeSearchWodType('FOR TIME')" hide-details label="FOR TIME" v-on:click="this.handleCheckbox('FOR TIME')"></v-checkbox>
                                <v-checkbox :model-value="this.includeSearchWodType('OPEN')" hide-details label="OPEN" v-on:click="this.handleCheckbox('OPEN')"></v-checkbox>
                            </v-card>
                        </div>
<!--                        <div>-->
<!--                            <h3>Mouvements :</h3>-->
<!--                            <v-combobox-->
<!--                                style="margin-top: 10px"-->
<!--                                v-model="this.search.movements"-->
<!--                                :items="this.movements"-->
<!--                                label="Choisis des mouvements"-->
<!--                                multiple-->
<!--                                chips-->
<!--                            ></v-combobox>-->
<!--                        </div>-->
                        <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px">
                            <v-btn style="margin-right: 30px" variant="elevated" color="info" v-on:click="this.apply">Appliquer</v-btn>
                            <v-btn variant="elevated" color="error" v-on:click="this.close">Fermer</v-btn>
                        </div>
                    </v-container>
                </v-main>
            </v-layout>
        </v-card>
    </v-container>
    </v-dialog>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import {mapState} from "vuex";

export default {
    name: "SearchWods",
    components: {VueDatePicker},
    props: {
        s: {
            required: true,
            type: Object,
        }
    },
    data () {
        return {
            dialogVisible: true,
            items: [
                'AMRAP',
                'EMOM',
                'OPEN',
            ],
            search: {
                wodTypes: [],
                dates: [],
            }
        }
    },
    async created() {
        this.search = this.s;
        console.log(this.search);
    },
    computed: {
        ...mapState(['cloud']),
    },
    methods: {
        handleCheckbox(type) {
            if (this.search.wodTypes.includes(type)) {
                const index = this.search.wodTypes.indexOf(type);
                if (index !== -1) {
                    this.search.wodTypes.splice(index, 1);
                }
            } else {
                this.search.wodTypes.push(type);
            }
        },
        includeSearchWodType(type) {
            return this.search.wodTypes.includes(type);
        },
        apply() {
            this.dialogVisible = false;
            if (!this.search.dates) {
                this.search.dates = [];
            }
            console.log(this.search)
            let searchFilters = [];
            searchFilters.push(...this.search.wodTypes);
            this.search.dates.forEach(date => {
                searchFilters.push(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());
            })
            this.cloud.searchFilters = searchFilters;
            this.$emit("depth-search", this.search);
        },
        close() {
            this.dialogVisible = false;
            this.$emit("close-depth-search-vue")
        }
    }
}
</script>

<style scoped>

</style>