<template>
    <v-row class="ma-0">
        <v-dialog v-model="dialog" width="1024">
            <template v-slot:activator="{ props }">
                <v-btn v-if="activeByButton" append-icon="mdi-dots-vertical" v-bind="props"><h2>{{ pr.description }}</h2></v-btn>
                <h5 v-else v-bind="props">PR {{ pr.movement }}</h5>
            </template>
            <v-card>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" :style="{backgroundColor: pr.color, borderRadius: '10px'}">
                            <h2>{{ pr.movement }}</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text style="padding: 27px">
                    <v-row>
                        <v-col cols="2">
                            <v-btn elevation="0" :style="{backgroundColor: pr.color, borderRadius: '10px'}"><h3>Index</h3></v-btn>
                        </v-col>
                        <v-col cols="3">
                            <v-btn elevation="0" :style="{backgroundColor: pr.color, borderRadius: '10px'}"><h3>Description</h3></v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn elevation="0" :style="{backgroundColor: pr.color, borderRadius: '10px'}"><h3>Date de réalisation</h3></v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(pr, index) in prsHistory" :key="pr.id" style="align-items: center; margin-top: 0">
                        <v-col cols="2">
                            <h3 style="margin-left: 10px">{{ index + 1 }}</h3>
                        </v-col>
                        <v-col cols="3">
                            <h3 style="margin-left: 10px">{{ pr.description }}</h3>
                        </v-col>
                        <v-col cols="3">
                            <h3 style="margin-left: 10px">{{ getDateWithBasicFormatDateParam(new Date(pr.dateCreate)) }}</h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col v-if="activeByButton && this.myProfileId === this.pr.userId" cols="auto">
                            <v-btn variant="text" icon="mdi-close-box" elevation="0" style="border-radius: 5px" v-on:click="this.removePr(pr)"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#2196f390" variant="elevated" v-on:click="this.closeDialog">
                        Fermer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {GetSpecificPrsByMovement, RemovePR} from "@/db/profile";
import {getDateWithBasicFormatDateParam} from "../utils/strings";
import MySnackBar from "@/components/MySnackBar.vue";
import {mapState} from "vuex";

export default {
    name: "SpecificPRDialog",
    components: {MySnackBar},
    emits: ["refreshProfileView"],
    computed: {
        ...mapState(['user']),
    },
    props: {
        pr: {
            type: Object,
            required: true
        },
        activeByButton: {
            type: Boolean,
            required: true
        }
    },
    watch: {
        async dialog(newValue) {
            if (newValue) {
                await this.handleDialogOpen()
            }
        }
    },
    data: () => ({
        dialog: false,
        prsHistory: [],
        mustBeRefresh: false,
        myProfileId: localStorage.getItem('profileId'),
        markers: [
            { label: '12/04/2022', value: 100 },
            { label: '22/04/2022', value: 110 },
            { label: '03/09/2022', value: 120 },
        ],
    }),
    methods: {
        getDateWithBasicFormatDateParam,
        async handleDialogOpen() {
            console.log(this.pr);
            this.prsHistory = await GetSpecificPrsByMovement(this.pr.userId, this.pr.movement);
            console.log(this.prsHistory);
        },
        async removePr(pr) {
            try {
                await RemovePR(pr.id);
                const index = this.prsHistory.findIndex(p => p.id === pr.id);
                this.prsHistory.splice(index, 1);
                this.mustBeRefresh = true;
            } catch (err) {
                console.log(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion")
            }
        },
        closeDialog() {
            this.dialog = false;
            if (this.mustBeRefresh) {
                this.$emit("refreshProfileView")
            }
        }
    }
}
</script>

<style scoped>

</style>