<template>
    <v-row class="ma-0">
        <v-dialog v-model="dialog" width="1024">
            <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" prepend-icon="mdi-star-box-outline" title="Profils favoris"></v-list-item>
            </template>
            <v-card>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" :style="{backgroundColor: my_blue(), borderRadius: '10px'}">
                            <h2>Profils Favoris</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text style="padding: 27px">
                    <v-row style="align-items: center; margin-top: 0">
                        <v-col cols="3">
                            <v-btn elevation="0" :style="{backgroundColor: my_yellow(), borderRadius: '10px'}"><h3>Pseudo</h3></v-btn>
                        </v-col>
                        <v-col cols="5">
                            <v-btn elevation="0" :style="{backgroundColor: my_yellow(), borderRadius: '10px'}"><h3>Description</h3></v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn elevation="0" :style="{backgroundColor: my_yellow(), borderRadius: '10px'}"><h3>Nom de la box</h3></v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="profile in profiles" :key="profile.id" style="align-items: center; margin-top: 0">
                        <v-col cols="3">
                            <h3 style="margin-left: 10px">{{ profile.pseudo }}</h3>
                        </v-col>
                        <v-col cols="5">
                            <h3>{{ profile.description }}</h3>
                        </v-col>
                        <v-col cols="auto" style="margin-left: 10px">
                            <h3>{{ profile.boxName }}</h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-btn variant="text" icon="mdi-arrow-right" elevation="0" style="border-radius: 5px" v-on:click="this.goToProfile(profile)"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#2196f390" variant="elevated" v-on:click="this.closeDialog">
                        Fermer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {GetProfilesFromFavoriteUser} from "@/db/profile";
import {getDateWithBasicFormatDateParam} from "../utils/strings";
import MySnackBar from "@/components/MySnackBar.vue";
import {mapState} from "vuex";
import {my_blue, my_yellow} from "@/utils/enums";

export default {
    name: "FavoriteProfileDialog",
    components: {MySnackBar},
    emits: ["refreshProfileView"],
    computed: {
        ...mapState(['user']),
    },
    watch: {
        async dialog(newValue) {
            if (newValue) {
                await this.handleDialogOpen()
            }
        }
    },
    data: () => ({
        dialog: false,
        profiles: [],
    }),
    methods: {
        my_blue() {
            return my_blue
        },
        my_yellow() {
            return my_yellow
        },
        getDateWithBasicFormatDateParam,
        async handleDialogOpen() {
            await this.getProfiles();
        },
        async getProfiles() {
            try {
                this.profiles = await GetProfilesFromFavoriteUser();
                console.log(this.profiles);
            } catch (err) {
                console.log(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion")
            }
        },
        closeDialog() {
            this.dialog = false;
            if (this.mustBeRefresh) {
                this.$emit("refreshProfileView")
            }
        },
        goToProfile(profile) {
            console.log(profile);
            this.$router.push(`/profile/${profile.user_id}`);
        }
    }
}
</script>

<style scoped>

</style>