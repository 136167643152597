<template>
    <v-row class="mx-4">
        <v-col>
            <v-card
              class="mx-auto pa-0"
              color="transparent"
              :elevation="0"
            >
                <v-card-text>
                    <v-row style="align-items: center">
                        <v-col cols="auto">
                            <BackButton></BackButton>
                        </v-col>
                        <v-col>
                            <v-text-field
                              v-model="cloud.searchField"
                              density="compact"
                              variant="solo"
                              label="Rechercher..."
                              prepend-inner-icon="mdi-magnify"
                              clearable
                              single-line
                              hide-details
                              @click:clear="handleClear"
                              @keyup.enter="this.search"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                variant="elevated"
                                style="align-items: center"
                                color="#2196f390"
                                v-on:click="this.search"
                            >
                                Rechercher
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                variant="elevated"
                                style="align-items: center; background-color: #F1C40F90"
                                color="#F1C40F"
                                v-on:click="this.openDepthSearch = true"
                            >
                                Filtres
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
    <v-card>
        <v-layout>
            <v-navigation-drawer permanent>
                <template v-slot:prepend>
                    <div class="pa-2">
                        <v-card
                            class="my-card"
                            prepend-icon="mdi-folder-multiple"
                            variant="tonal"
                        >
                            <template v-slot:title>
                                <h6>Mes dossiers favoris</h6>
                            </template>
                        </v-card>
                    </div>
                </template>
                <v-list>
                    <h4 v-if="!foldersInFavoriteList || foldersInFavoriteList.length === 0" style="text-align: center; opacity: 0.5">Aucun dossiers favoris</h4>
                    <v-row
                        v-else
                        v-for="(folder, index) in this.foldersInFavoriteList"
                        :key="folder.name"
                        style="
                            align-items: center;
                            justify-content: space-between;
                            margin-left: 1px;
                            margin-right: 20px;
                        ">
                        <v-col cols="auto">
                            <v-btn elevation="0" prepend-icon="mdi-folder" v-on:click="setOpenDirectory(folder)">
                                <h5>{{ folder.name === '.' ? 'Cloud' : folder.name }}</h5>
                            </v-btn>
                        </v-col>
                        <v-icon icon="mdi-close" v-on:click="this.removeDirInFavoriteList(folder, index)"></v-icon>
                    </v-row>
                </v-list>
            </v-navigation-drawer>
            <v-main style="height: auto; min-height: 90vh">
                <v-card v-if="!displayDepthSearch()" style="margin: 8px; padding: 5px; background-color: #2196f390">
                    <v-row class="mx-4" style="align-items: center">
                        <v-col cols="auto">
                            <v-icon icon="mdi-arrow-left" v-on:click="this.goToPreviousFolder"></v-icon>
                        </v-col>
                        <v-col cols="auto">
                            <h3>{{ pathCurrentFolder }}</h3>
                        </v-col>
                    </v-row>
                </v-card>
                <div style="margin: 20px" v-if="!this.loading">
                    <v-row class="mb-1" style="align-items: center" v-if="!displayDepthSearch()">
                        <v-col cols="auto">
                            <NewDirectoryView @createDirectoryDialogClosed="getAllDirectoriesInParent" :current-dir="cloud.currentDirectory.name"></NewDirectoryView>
                        </v-col>
                        <v-col v-if="!displayDepthSearch()">
                            <v-menu>
                                <template v-slot:activator="{ props }">
                                    <v-icon icon="mdi-sort" v-bind="props"></v-icon>
                                </template>
                                <v-list>
                                    <v-list-item :key="0">
                                        <v-btn v-on:click="this.sortByName(this.childFolders)">Trier par nom</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <h3 v-if="!this.childFolders || this.childFolders.length === 0" style="opacity: 0.5">Aucun dossier</h3>
                    <v-row dense v-if="!displayDepthSearch()">
                        <v-col
                            v-for="folder in childFolders"
                            :key="folder.name"
                            cols="auto"
                            style="min-width: 220px"
                        >
                            <v-card
                                class="mx-auto text-center"
                                max-width="200"
                                variant="text"
                                elevation="5"
                                style="border-radius: 12px"
                            >
                                <v-icon icon="mdi-folder-edit" style="font-size: 70px" v-on:click="this.setOpenDirectory(folder);"></v-icon>
                                <v-card-title style="font-size:18px">{{ folder.name }}</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn variant="text" size="small" v-bind="props">Details</v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item :key="0">
                                                <ModifyDialog :title="items[0].title + ' ' + folder.name" :logo-button="items[0].logo" :title-button="items[0].title" :callback="updateDirectory" :id="folder.name" :open-default="false"></ModifyDialog>
                                            </v-list-item>
                                            <v-list-item :key="1">
                                                <v-btn size="small" :prepend-icon="items[1].logo" v-on:click="deleteDirectory(folder.name)">{{ items[1].title}}</v-btn>
                                            </v-list-item>
                                            <v-list-item :key="2">
                                                <v-btn size="small" :prepend-icon="items[4].logo" v-on:click="addNewDirInFavoriteList(folder)">{{ items[4].title}}</v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mb-1" style="align-items: center">
                        <v-col cols="auto" v-if="!displayDepthSearch()">
                            <NewWodView @refresh="this.refresh" ref="newWodView" :current-dir="cloud.currentDirectory.name" :program="-1"></NewWodView>
                        </v-col>
                        <v-col v-if="!displayDepthSearch()">
                            <v-menu>
                                <template v-slot:activator="{ props }">
                                    <v-icon icon="mdi-sort" v-bind="props"></v-icon>
                                </template>
                                <v-list>
                                    <v-list-item :key="0">
                                        <v-btn v-on:click="this.sortByDate(this.childFiles)">Trier par date</v-btn>
                                    </v-list-item>
                                    <v-list-item :key="1">
                                        <v-btn v-on:click="this.sortByName(this.childFiles)">Trier par nom</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                        <v-col v-if="displayDepthSearch()">
                            <v-btn size="large" class="px-4" variant="text">Wods</v-btn>
                        </v-col>
                    </v-row>
                    <h3 v-if="!this.childFiles || this.childFiles.length === 0" style="opacity: 0.5">Aucun wod</h3>
                    <v-row dense>
                        <v-col
                            v-for="(file, index) in childFiles"
                            :key="file.name"
                            cols="auto"
                            style="min-width: 220px"
                        >
                            <v-card
                                class="mx-auto text-center"
                                max-width="200"
                                variant="text"
                                elevation="5"
                                style="border-radius: 12px"
                            >
                                <v-icon icon="mdi-file-document" style="font-size: 70px" v-on:click="this.showWodDetails(file)"></v-icon>
                                <v-card-title style="font-size:18px">{{ file.name }}</v-card-title>
                                <v-card-subtitle>{{ file.type }} - {{ getDateWithBasicFormat(file.date) }}</v-card-subtitle>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn variant="text" size="small" v-bind="props">Details</v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item :key="0">
                                                <v-btn size="small" :prepend-icon="items[3].logo" v-on:click="editWod(file)">{{ items[3].title}}</v-btn>
                                            </v-list-item>
                                            <v-list-item :key="1">
                                                <v-btn size="small" :prepend-icon="items[1].logo" v-on:click="deleteFile(file, index)">{{ items[1].title}}</v-btn>
                                            </v-list-item>
                                            <v-list-item :key="2">
                                                <v-btn size="small" :prepend-icon="items[2].logo" v-on:click="onMoveWodDialog(file)" elevation="0">{{ items[2].title}}</v-btn>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <MyProgressCircular></MyProgressCircular>
                </div>
            </v-main>
        </v-layout>
    </v-card>
    <SearchWods v-if="openDepthSearch" @depth-search="this.depthSearch" @close-depth-search-vue="this.openDepthSearch = false" :s="cloud.searchObject"></SearchWods>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
    <MoveWodDialog @refresh="this.refresh" ref="moveWodDialog" :wod="this.currentWod" :path="this.pathCurrentFolder"></MoveWodDialog>
    <WodDialog ref="wodDialog"></WodDialog>
</template>

<script>
import {
    deleteDirectory, DeleteDirInFavoriteList,
    getAllDirectories,
    getAllWodsInDirectory, GetDirectoryById,
    getDirsInFavoriteList, InsertDirInFavoriteList,
    updateDirectoryName
} from "@/db/cloud";
import {getAllFolders, getChildFolders, getFolderPath, getParentFolder} from "@/utils/folder";
import {getDateWithBasicFormat, isEqualDate} from "../utils/strings";
import {deleteWod, getAllWodsFromUser} from "@/db/wods";
import {mapMutations, mapState} from "vuex";
import ModifyDialog from "@/components/ModifyDialog.vue";
import NewWodView from "@/views/NewWodView.vue";
import NewDirectoryView from "@/views/NewDirectoryView.vue";
import MySnackBar from "@/components/MySnackBar.vue";
import SearchWods from "@/components/wods/SearchWods.vue";
import BackButton from "@/components/BackButton.vue";
import MoveWodDialog from "@/components/MoveWodDialog.vue";
import WodDialog from "@/components/WodDialog.vue";
import MyProgressCircular from "@/components/MyProgressCircular.vue";

export default {
    name: "CloudView",
    components: {
        MyProgressCircular,
        WodDialog,
        MoveWodDialog, BackButton, SearchWods, MySnackBar, NewDirectoryView, NewWodView, ModifyDialog},
    data: () => ({
        folders: [],
        allFolders: [],
        pathCurrentFolder: "/",
        childFolders: [],
        childFiles: [],
        items: [
            {title : "Renommer", logo: "mdi-pencil"},
            {title : "Supprimer", logo: "mdi-trash-can"},
            {title : "Déplacer", logo: "mdi-file-move"},
            {title : "Modifier", logo: "mdi-file-edit"},
            {title: "Favoris", logo: "mdi-star-box-outline"}
        ],
        allWods: [],
        foldersInFavoriteList: [],
        openDepthSearch: false,
        moveWodDialog: false,
        currentWod: null,
        folderId: 0,
        loading: true,
    }),
    beforeMount() {
        this.folderId = this.$route.params.dirId;
        console.log("this.fodlerId=" + this.folderId);
    },
    async mounted() {
        try {
            await this.getAllDirectoriesInParent();
            await this.getAllWodsInDirectory();
            this.foldersInFavoriteList = await getDirsInFavoriteList();

            if (this.folderId !== "0") {
                const dir = await GetDirectoryById(this.folderId);
                await this.setOpenDirectory(dir);
            }
        } catch (err) {
            this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            console.error(err)
        }
        if (this.cloud.displayResultDepthSearch) {
            await this.initCloud();
        }
    },
    computed: {
        ...mapState(['cloud']),
    },
    methods: {
        ...mapMutations(['setCloud', 'resetSearchCloud']),
        getDateWithBasicFormat,
        async getAllDirectoriesInParent() {
            try {
                this.loading = true;
                const response = await getAllDirectories();
                if (response != null) {
                    response.forEach(dir => {
                        let folder = {
                            id: dir.id,
                            parent_name: dir.parent_name,
                            name: dir.name,
                            open: false,
                            indent: 0,
                        }
                        if (!this.folders.find(folder => folder.name === dir.name && folder.parent_name === dir.parent_name)) {
                            if (folder.name !== ".") {
                                this.folders.push(folder)
                            }
                        }
                    });
                    this.allFolders = getAllFolders(this.folders)
                    this.pathCurrentFolder = getFolderPath(this.folders, this.cloud.currentDirectory.name);
                    this.childFolders = getChildFolders(this.folders, this.cloud.currentDirectory.name);
                    this.loading = false;
                }
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        async setOpenDirectory(folder) {
            this.loading = true;
            this.cloud.currentDirectory = folder;
            folder.open = !folder.open;

            if (!folder.open)
            {
                this.cloud.currentDirectory = getParentFolder(this.folders, folder.name)
            }

            try {
                await this.getAllWodsInDirectory();
                this.allFolders = getAllFolders(this.folders);
                if (!folder.open) {
                    this.pathCurrentFolder = getFolderPath(this.folders, folder.parent_name);
                    this.childFolders = getChildFolders(this.folders, folder.parent_name);
                } else {
                    this.pathCurrentFolder = getFolderPath(this.folders, this.cloud.currentDirectory.name);
                    this.childFolders = getChildFolders(this.folders, this.cloud.currentDirectory.name);
                }
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        async goToPreviousFolder() {
            this.loading = true;
            this.cloud.currentDirectory.open = !this.cloud.currentDirectory.open;
            this.cloud.currentDirectory = getParentFolder(this.folders, this.cloud.currentDirectory.name);

            try {
                await this.getAllWodsInDirectory();
                this.allFolders = getAllFolders(this.folders);
                this.pathCurrentFolder = getFolderPath(this.folders, this.cloud.currentDirectory.name);
                this.childFolders = getChildFolders(this.folders, this.cloud.currentDirectory.name);
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        async updateDirectory(name, newName) {
            try {
                this.loading = true;
                const index = this.folders.findIndex(folder => folder.name === name);
                if (index !== -1)
                {
                    this.folders.splice(index, 1);

                    await updateDirectoryName(name, newName)
                    this.$refs.mySnackbarRef.active("Nom du dossier mis à jour", "success")
                    await this.getAllDirectoriesInParent();
                }
                else
                {
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                }
                this.loading = false;
            } catch (e) {
                if (e.response.data.error.includes("Directory already exists")) {
                    this.$refs.mySnackbarRef.active("Un dossier avec ce nom existe déjà.", "warning")
                } else {
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                }
                console.error(e)
            }
        },
        editWod(wodToEdit) {
            console.log(wodToEdit)
            this.$refs.newWodView.wodId = wodToEdit.id;
            this.$refs.newWodView.dialog = true;
        },
        onMoveWodDialog(file) {
            console.log(file);
            this.currentWod = file;
            this.$refs.moveWodDialog.moveDialog = true;
        },
        async deleteDirectory(directoryName) {
            try {
                this.loading = true;
                const index = this.folders.findIndex(folder => folder.name === directoryName);
                if (index !== -1)
                {
                    await deleteDirectory(directoryName);
                    this.folders.splice(index, 1);
                    this.$refs.mySnackbarRef.active("Dossier supprimé", "success");

                    await this.getAllDirectoriesInParent();
                }
                else
                {
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                }
                this.loading = false;
            } catch (err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
        },
        async deleteFile(file, index) {
            try {
                this.loading = true;
                if (index !== -1)
                {
                    await deleteWod(file.id)
                    this.childFiles.splice(index, 1);
                    this.$refs.mySnackbarRef.active("Wod supprimé", "success");

                    await this.getAllWodsInDirectory();
                }
                else
                {
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                }
                this.loading = false;
            } catch (err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
        },
        async getAllWodsInDirectory() {
            try {
                this.loading = true;
                this.childFiles = await getAllWodsInDirectory(this.cloud.currentDirectory.name);
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion" + this.cloud.currentDirectory.name, "error")
                console.error(err)
            }
        },
        async search() {
            try {
                this.loading = true;
                this.allWods = await getAllWodsFromUser();
                this.cloud.displayResultDepthSearch = true;
                this.childFiles = this.allWods.filter((wod) => wod.name.toLowerCase().includes(this.cloud.searchField.toLowerCase()));
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        async depthSearch(search) {
            this.openDepthSearch = false;
            this.cloud.searchObject = search;
            this.cloud.displayResultDepthSearch = true;
            console.log(search);
            if (search.dates.length === 0 && search.wodTypes.length === 0) {
                this.childFiles = this.allWods.filter((wod) => wod.name.toLowerCase().includes(this.cloud.searchField.toLowerCase()));
                await this.getAllWodsInDirectory();
                this.cloud.displayResultDepthSearch = false;
                return;
            }
            try {
                this.loading = true;
                if (this.cloud.searchField === "") {
                    this.allWods = await getAllWodsFromUser();
                } else {
                    this.allWods = this.allWods.filter((wod) => wod.name.toLowerCase().includes(this.cloud.searchField.toLowerCase()));
                }

                if (this.allWods && this.allWods.length > 0) {
                    this.childFiles = this.allWods.filter((wod) => {
                        if (search.wodTypes.includes(wod.type)) {
                            return search.wodTypes.includes(wod.type);
                        }
                        const date1 = new Date(wod.date)
                        let find = false;
                        search.dates.forEach(item => {
                            const date2 = new Date(item);
                            if (isEqualDate(date1, date2)) {
                                find = true;
                                return true;
                            }
                        });
                        if (find) {
                            return true;
                        }
                    });
                }
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        updateSearchFilters(filter) {
            this.cloud.searchObject.dates = this.cloud.searchObject.dates.filter(date => {
                const dateString = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
                return dateString !== filter;
            })
            this.cloud.searchObject.wodTypes = this.cloud.searchObject.wodTypes.filter(item => item !== filter)

            this.depthSearch(this.cloud.searchObject);
        },
        copyPath() {
            console.log("copier dans le presse papier")
        },
        displayDepthSearch() {
            return this.cloud.displayResultDepthSearch;
        },
        async handleClear() {
            try {
                this.loading = true;
                await this.getAllWodsInDirectory();
                this.childFolders = getChildFolders(this.folders, this.cloud.currentDirectory.name);
                this.resetSearchCloud();
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        showWodDetails(item) {
            console.log(item);
            this.currentWod = item;
            this.$refs.wodDialog.wodId = this.currentWod.id;
            this.$refs.wodDialog.wodDialog = true;
        },
        async initCloud() {
            this.loading = true;
            await this.search();
            await this.depthSearch(this.cloud.searchObject);
            this.loading = false;
        },
        async removeDirInFavoriteList(folder, index) {
            try {
                this.loading = true;
                await DeleteDirInFavoriteList(folder.id);
                this.foldersInFavoriteList.splice(index, 1);
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
                console.error(err);
            }
        },
        async addNewDirInFavoriteList(folder) {
            console.log(folder);
            try {
                this.loading = true;
                await InsertDirInFavoriteList(folder.id);
                if (!this.foldersInFavoriteList) {
                    this.foldersInFavoriteList = [];
                }
                this.foldersInFavoriteList.push(folder);
                this.loading = false;
            } catch (err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        },
        async refresh() {
            try {
                this.loading = true;
                console.log("refresh");
                await this.getAllDirectoriesInParent();
                await this.getAllWodsInDirectory();
                this.loading = false;
            } catch(err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        },
        sortByName(aDatas) {
            if (!aDatas) {
                return;
            }
            aDatas.sort(function (a, b) {
                const nomA = a.name.toUpperCase();
                const nomB = b.name.toUpperCase();
                if (nomA < nomB) {
                    return -1;
                }
                return 1;
            })
        },
        sortByDate(aDatas) {
            if (!aDatas) {
                return;
            }
            aDatas.sort(function (a, b) {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                if (dateA >= dateB) {
                    return -1;
                }
                return 1;
            })
        }
    }
}
</script>

<style>
.mdi-folder-edit::before {
    color: #F1C40F90;
    font-size: 50px;
}
.mdi-file-document::before {
    color: #16A08590;
    font-size: 50px;
}
.mdi-folder-multiple::before {
    color: #2196f390;
    font-size: 18px;
}
.mdi-folder::before, .mdi-folder-open::before {
    color: #F1C40F90;
    font-size: 18px;
}
.flex-grow-0 {
    flex-grow: 0;
}
.my-card {
    /*max-width: 150px;*/
    background-color: transparent;
    elevation: 0;
}

.v-list-item__content {
    grid-area: unset;
}

.v-card-item {
    padding: 0;
}
.scrollable-page {
    overflow-y: auto;
    height: 90vh; /* Ajustez la hauteur selon vos besoins */
}

</style>