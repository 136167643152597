<template>
    <v-dialog class="px-11" v-model="dialog" persistent width="1024">
        <template v-slot:activator="{ props }">
            <v-btn style="background-color: #16A08590" size="large" class="px-4" append-icon="mdi-plus-circle" variant="text" type="submit" v-bind="props">Dossiers</v-btn>
        </template>
        <v-card>
            <v-card-title class="pa-6">
                <span class="my-title">Nouveau dossier</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field label="Nom du dossier" required v-model="name" variant="solo"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#2196f390" variant="elevated" @click="dialog = false">
                    Fermer
                </v-btn>
                <v-btn color="#F1C40F90" variant="elevated" v-on:click="createNewDirectory" @click="dialog = false">
                    Créer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>
import MySnackBar from "@/components/MySnackBar.vue";
import {createNewDirectory} from "@/db/cloud";
import {my_green} from "@/utils/enums";

export default {
    name: "NewDirectoryView",
    components: {MySnackBar},
    emits: ['createDirectoryDialogClosed'],
    data: () => ({
        dialog: false,
        name: '',
    }),
    props: {
        currentDir: {
            type: String,
            required: true,
        }
    },
    methods: {
        my_green() {
            return my_green
        },
        async createNewDirectory() {
            try {
                if (this.name !== "") {
                    await createNewDirectory(this.name, this.currentDir);
                    this.$emit("createDirectoryDialogClosed")
                    this.$refs.mySnackbarRef.active("Dossier crée", "success")
                } else {
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                }
            } catch (err) {
                if (err.response.data.error.includes("Directory already exists")) {
                    this.$refs.mySnackbarRef.active("Un dossier avec ce nom existe déjà", "warning")
                } else {
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                }
                console.log(err)
            }
        }
    }
}

</script>

<style scoped>
.my-title {
    font-family: 'Balsamiq', sans-serif;
    font-size: 32px;
    font-weight: bold;
}
</style>