<template>
    <v-app>
<!--        <v-container fluid class="pb-0">-->
<!--            <v-switch @click="toggleTheme" :label="$t('dark_mode')" :model-value="true">Toggle theme</v-switch>-->
<!--        </v-container>-->
        <v-main>
            <v-slide-y-transition mode="out-in">
                <router-view />
            </v-slide-y-transition>
        </v-main>
    </v-app>
</template>

<script>
import {useTheme} from "vuetify";

export default {
    name: "app",
    setup () {
       const theme = useTheme()

        theme.global.name.value = 'dark'

       return {
           theme,
           toggleTheme: () => theme.global.name.value = 'light'
       }
    }
};
</script>