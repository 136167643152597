import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router';
import i18n from './i18n';
import VueCookies from "vue-cookies";
import store from './store';

loadFonts();

createApp(App)
    .use(VueCookies)
    .use(i18n)
    .use(router)
    .use(vuetify)
    .use(store) // Utilisez votre store Vuex en l'ajoutant à l'application
    .mount('#app');