<template>
    <div v-if="!loading">
        <v-card>
            <v-layout>
                <v-navigation-drawer permanent style="height: 100vh">
                    <v-list style="margin: 10px">
                        <BackButton style="margin-bottom: 10px"></BackButton>
                        <v-list-item v-if="isMe() === true" v-on:click="this.goToSettings" prepend-icon="mdi-cog" title="Paramètres"></v-list-item>
                        <v-list-item v-if="isMe() === true" v-on:click="this.goToMeasurement" prepend-icon="mdi-tape-measure" title="Mensurations"></v-list-item>
                    </v-list>
                    <template v-slot:append>
                        <div v-if="isMe() === true" class="pa-2">
                            <v-list-item :style="{backgroundColor: my_red(), borderRadius: '10px'}" v-on:click="logout" prepend-icon="mdi-logout" title="Se déconnecter"></v-list-item>
                        </div>
                    </template>
                </v-navigation-drawer>
                <v-main style="height: auto; min-height: 100vh; margin-left: 16px; margin-right: 16px">
                    <v-row style="align-items: center">
                        <v-col cols="auto">
                            <v-icon size="120" icon="mdi-account"></v-icon>
                        </v-col>
                        <v-col cols="auto">
                            <v-row style="align-items: center">
                                <v-col cols="auto">
                                    <h2>{{ this.profile.pseudo }}</h2>
                                </v-col>
                                <v-col v-if="this.profile.boxName !== ''" cols="auto">
                                    <h3>({{ this.profile.boxName}})</h3>
                                </v-col>
                                <v-col v-if="isMe() === true" cols="auto">
                                    <EditProfile :profile="this.profile" @changeDataProfile="changeDataProfile"></EditProfile>
                                </v-col>
                            </v-row>
                            <h4 style="margin-left: 5px; margin-top: 10px">{{ this.profile.description }}</h4>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col v-if="isMe() === true" cols="auto" style="margin-right: 30px">
                            <v-btn :style="{ backgroundColor: this.profile.isPrivate ? null : '#F1C40F90', marginRight: '20px'}" v-on:click="this.changeStatus">Public</v-btn>
                            <v-btn :style="{ backgroundColor: !this.profile.isPrivate ? null : '#E74C3C90'}" v-on:click="this.changeStatus">Privé</v-btn>
                        </v-col>
                        <v-col v-else cols="auto" style="margin-right: 30px">
                            <v-btn variant="tonal" v-if="!isFavorite" icon="mdi-star-outline" style="border-radius: 5px" v-on:click="changeFavoriteStatus"></v-btn>
                            <v-btn variant="tonal" v-else icon="mdi-star" style="border-radius: 5px" v-on:click="changeFavoriteStatus"></v-btn>
                        </v-col>
                    </v-row>
                    <div class="ma-3 mt-8">
                        <v-row style="align-items: center">
                            <v-col cols="auto">
                                <h2 class="ma-2">Semaine en cours</h2>
                            </v-col>
                            <v-col cols="auto">
                                <v-icon icon="mdi-calendar-month" size="30" :style="{padding: '18px', borderRadius: '5px'}" v-on:click="goToCalendar"></v-icon>
                            </v-col>
                        </v-row>
                        <WeekCalendar :key="calendarKey" :profile-id="this.profileId" :program-id="-1"></WeekCalendar>
                    </div>
                    <div>
                        <v-row style="align-items: center">
                            <v-col cols="auto">
                                <h2 class="ml-2">Records personnels</h2>
                            </v-col>
                            <v-col v-if="isMe() === true" cols="auto">
                                <NewPRDialog @addNewPR="addNewPR" :suggested-movements="this.PRs"></NewPRDialog>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col
                                v-for="pr in PRs"
                                :key="pr.movement"
                                cols="auto"
                                style="min-width: 220px"
                            >
                                <v-card
                                    class="mx-auto text-center"
                                    max-width="200"
                                    variant="text"
                                    elevation="5"
                                    :style="{borderRadius: '10px', backgroundColor: pr.color, padding: '5px', margin: '10px'}"
                                >
                                    <div style="justify-content: center">
                                        <v-col cols="auto">
                                            <h3>{{ pr.movement }}</h3>
                                            <h5>{{ getDateWithBasicFormatDateParam(new Date(pr.dateCreate)) }}</h5>
                                        </v-col>
                                    </div>
                                    <v-row style="justify-content: center; margin-bottom: 2px">
                                        <v-col cols="auto">
                                            <SpecificPRDialog @refreshProfileView="refresh" :pr="pr" active-by-button></SpecificPRDialog>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-main>
            </v-layout>
        </v-card>
    </div>
    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
import WeekCalendar from "@/components/WeekCalendar.vue";
import {my_blue, my_red, my_yellow} from "@/utils/enums";
import EditProfile from "@/components/EditProfile.vue";
import MySnackBar from "@/components/MySnackBar.vue";

import 'vue3-carousel/dist/carousel.css'
import NewPRDialog from "@/components/NewPRDialog.vue";
import {getDateWithBasicFormatDateParam} from "@/utils/strings";
import {
    CheckIfInMyFavoriteList,
    DeleteFavoriteProfile,
    GetProfile,
    GetPRs, InsertNewFavoriteProfile,
    InsertNewPR,
    UpdateProfile
} from "@/db/profile";
import SpecificPRDialog from "@/components/SpecificPRDialog.vue";

export default {
    name: "ProfileView",
    components: {
        SpecificPRDialog,
        NewPRDialog, MySnackBar, EditProfile, WeekCalendar, BackButton},
    data: () => ({
        profile: {
            pseudo: "",
            description: "",
            isPrivate: false,
            boxName: "",
        },
        profileId: "0",
        myProfileId: "",
        isFavorite: true,
        PRs: [],
        calendarKey: 0,
        loading: true,
    }),
    async beforeMount()
    {
        this.myProfileId = localStorage.getItem('profileId');
        this.profileId = this.$route.params.profileId;
        await this.checkIfInMyFavoriteList()
        await this.getProfile();
        await this.getPRs();
        this.loading = false;
    },
    methods: {
        getDateWithBasicFormatDateParam,
        my_red() {
            return my_red
        },
        my_yellow() {
            return my_yellow
        },
        my_blue() {
            return my_blue
        },
        async changeStatus() {
            this.profile.isPrivate = !this.profile.isPrivate;
            try {
                console.log(this.profile);
                await UpdateProfile(this.profile);
                this.$refs.mySnackbarRef.active("Profil mis à jour", "success");
            } catch (err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
        },
        async checkIfInMyFavoriteList() {
            try {
                const response = await CheckIfInMyFavoriteList(this.profileId);
                if (response === 0) {
                    this.isFavorite = false
                }
            } catch (err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
        },
        backPage() {
            this.$router.push('/')
        },
        openDialogToEditProfile() {
            console.log("OPEN")
        },
        async changeFavoriteStatus() {
            if (!this.isFavorite) {
                try {
                    await InsertNewFavoriteProfile(this.profileId);
                    this.$refs.mySnackbarRef.active("Ajouté dans la liste des favoris !", "info");
                    this.isFavorite = !this.isFavorite;
                } catch (err) {
                    console.error(err);
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
                }
            } else {
                try {
                    await DeleteFavoriteProfile(this.profileId);
                    this.$refs.mySnackbarRef.active("Retiré de la liste des favoris !", "info");
                    this.isFavorite = !this.isFavorite;
                } catch (err) {
                    console.error(err);
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
                }
            }
        },
        async addNewPR(newPR) {
            try {
                await InsertNewPR(newPR);
                await this.refresh();
                this.$refs.mySnackbarRef.active("Nouveau PR ajouté", "success")
            } catch(err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
        },
        async refresh() {
            try {
                await this.getPRs()
                this.calendarKey++;
            } catch(err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
        },
        async changeDataProfile(newProfile) {
            try {
                await UpdateProfile(newProfile);
                this.profile.pseudo = newProfile.pseudo;
                this.profile.description = newProfile.description;
                this.profile.boxName = newProfile.boxName;
                this.profile.isPrivate = newProfile.isPrivate;

                this.$refs.mySnackbarRef.active("Profil mis à jour", "success");
            } catch(err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        },
        async getProfile() {
            try {
                this.profile = await GetProfile(this.profileId);
                console.log(this.profile);
            } catch(err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        },
        async getPRs() {
            try {
                this.PRs = await GetPRs(this.profileId);
                console.log(this.PRs);
            } catch(err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        },
        goToSettings() {
            console.log("GO TO SETTINGS");
        },
        goToMeasurement() {
            console.log("GO TO MEASUREMENT");
        },
        goToCalendar() {
            this.$router.push({ name: 'Calendar', query: { profileId: this.profileId, programId: -1 }})
        },
        logout() {
            this.$cookies.set('token', null, new Date(0));
            localStorage.removeItem('profileId');
            this.$router.push('/login')
        },
        isMe() {
            return this.profileId === this.myProfileId;
        }
    }
}
</script>

<style>
.mdi-star {
    color: #F1C40F;
}
</style>