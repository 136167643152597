<template>
    <div v-if="hasAccess">
        <v-card>
            <v-layout>
                <v-navigation-drawer permanent>
                    <template v-slot:prepend>
                        <div class="pa-2">
                            <v-container fluid class="pb-0">
                                <v-switch @click="toggleTheme" :label="$t('dark_mode')" :model-value="true">Toggle theme</v-switch>
                            </v-container>
                        </div>
                    </template>
                    <v-list style="margin: 10px">
                        <v-list-item v-on:click="this.goToWods" prepend-icon="mdi-weight-lifter" title="Mes Wods"></v-list-item>
                        <v-list-item v-on:click="this.goToCalendar" prepend-icon="mdi-calendar-month" title="Calendrier"></v-list-item>
                        <v-list-item v-on:click="this.goToProfile" prepend-icon="mdi-account-box" title="Mon profil"></v-list-item>
                        <v-list-item v-on:click="this.goToProgrammation" prepend-icon="mdi-calendar-outline" title="Programmation"></v-list-item>
                        <v-list-item v-on:click="this.goToEvents" prepend-icon="mdi-calendar-clock" title="Mes évènements"></v-list-item>
                        <FavoriteProfileDialog></FavoriteProfileDialog>
                    </v-list>
                </v-navigation-drawer>
                <v-main style="height: auto; min-height: 100vh">
                    <v-row class="mx-4">
                        <v-col>
                            <v-card
                                class="mx-auto pa-0"
                                color="transparent"
                                :elevation="0"
                            >
                                <v-card-text>
                                    <v-row style="align-items: center">
                                        <v-col>
                                            <v-menu v-model="menuSearchProfile" :close-on-content-click="false" transition="scale-transition">
                                                <template v-slot:activator="{ props }">
                                                    <v-text-field
                                                        v-bind="props"
                                                        density="compact"
                                                        variant="solo"
                                                        hide-details
                                                        single-line
                                                        label="Rechercher un profil"
                                                        v-model="this.selectedProfile.pseudo"
                                                        @input="this.handleSelected"
                                                    ></v-text-field>
                                                </template>
                                                <v-list v-if="selectedItemsBoxName.length > 0">
                                                    <v-row style="margin-left: 10px; margin-top: 2px; margin-bottom: 5px">
                                                        <v-col cols="auto" :style="{backgroundColor: my_yellow(), borderRadius: '10px', padding: '5px'}">
                                                            <h5>Les profils présents dans votre Box</h5>
                                                        </v-col>
                                                    </v-row>
                                                    <v-list-item v-for="item in this.selectedItemsBoxName" :key="item" v-on:click="this.selectedProfile = item; this.menuSearchProfile = false; this.searchProfile()">
                                                        <template v-slot:prepend>
                                                            <v-icon size="25" icon="mdi-account" style="margin-right: 10px"></v-icon>
                                                        </template>
                                                        <v-list-item-title>{{ item.pseudo }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.boxName }}</v-list-item-subtitle>
                                                    </v-list-item>
                                                </v-list>
                                                <v-list v-if="selectedItemsPseudo.length > 0" style="margin-top: 5px">
                                                    <v-list-item v-for="item in this.selectedItemsPseudo" :key="item" v-on:click="this.selectedProfile = item; this.menuSearchProfile = false; this.searchProfile()">
                                                        <template v-slot:prepend>
                                                            <v-icon size="25" icon="mdi-account" style="margin-right: 10px"></v-icon>
                                                        </template>
                                                        <v-list-item-title>{{ item.pseudo }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.boxName }}</v-list-item-subtitle>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn
                                                style="align-items: center"
                                                color="#2196f390"
                                                v-on:click="this.searchProfile"
                                            >
                                                Rechercher
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <div class="ma-3 mt-8">
                        <h2 class="ma-2">Semaine en cours</h2>
                        <WeekCalendar :profile-id="this.profileId" :program-id="-1"></WeekCalendar>
                    </div>
                    <ListWodsWithLimit :get-wods="this.getWodsWithLimit"></ListWodsWithLimit>
                </v-main>
            </v-layout>
        </v-card>
    </div>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
    <MyEventDialog ref="myEventDialog"></MyEventDialog>
</template>

<script>

import {checkAuth} from "@/router";
import {useTheme} from "vuetify";
import WeekCalendar from "@/components/WeekCalendar.vue";
import {mapMutations, mapState} from "vuex";
import {getDateWithBasicFormat, getDateWithBasicFormatDateParam} from "@/utils/strings";
import MySnackBar from "@/components/MySnackBar.vue";
import {GetProfileWithSpecificString, GetProfileWithSpecificStringAndBoxName} from "@/db/profile";
import {my_yellow} from "@/utils/enums";
import FavoriteProfileDialog from "@/components/FavoriteProfileDialog.vue";
import MyEventDialog from "@/components/MyEventDialog.vue";
import ListWodsWithLimit from "@/components/ListWodsWithLimit.vue";
import {getWodsWithLimit} from "@/db/wods";

export default {
    components: {ListWodsWithLimit, MyEventDialog, FavoriteProfileDialog, MySnackBar, WeekCalendar},
    computed: {
        ...mapState(['home']),
        filteredItems() {
            return this.handleSelected(this.selectedProfile.pseudo);
        }
    },
    data() {
        return {
            hasAccess: false,
            profileId: "",
            allProfiles: [],
            selectedProfile: {
                user_id: -1,
                pseudo: "",
                description: "",
                boxName: "",
                isPrivate: true,
            },
            menuSearchProfile: false,
            selectedItemsPseudo: [],
            selectedItemsBoxName: [],
        }
    },
    beforeMount() {
        this.profileId = localStorage.getItem('profileId');
    },
    async created() {
        await this.sAuthenticated();
    },
    setup () {
        const theme = useTheme()
        theme.global.name.value = 'dark'

        return {
            theme,
            toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
        }
    },
    methods: {
        my_yellow() {
            return my_yellow
        },
        getDateWithBasicFormatDateParam,
        getDateWithBasicFormat,
        ...mapMutations(['setCurrentDate', 'setElementCountPerPage']),
        async sAuthenticated() {
            const response = await checkAuth()
            console.log(response)
            if (response.hasAccess) {
                this.hasAccess = true
            }
        },
        test() {
            console.log("ELIE");
        },
        goToWods() {
            this.$router.push("/wods/0");
        },
        goToCalendar() {
            this.$router.push({ name: 'Calendar', query: { profileId: localStorage.getItem('profileId'), programId: -1 }})
        },
        goToProfile() {
            this.$router.push(`/profile/${localStorage.getItem('profileId')}`);
        },
        goToProgrammation() {
            this.$router.push('/programmation');
        },
        goToEvents() {
            this.$refs.myEventDialog.dialog = true;
        },
        searchProfile() {
            if (this.selectedProfile.user_id > -1) {
                this.$router.push(`/profile/${this.selectedProfile.user_id}`);
            }
        },
        async getProfiles(pseudo, boxName) {
            try {
                if (boxName === '') {
                    const res = await GetProfileWithSpecificString(pseudo);
                    return res ? res : [];
                } else {
                    const res = await GetProfileWithSpecificStringAndBoxName(pseudo, boxName)
                    return res ? res : [];
                }
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err);
                return [];
            }
        },
        reset() {
            this.selectedItemsPseudo = [];
            this.selectedItemsBoxName = [];
        },
        async handleSelected() {
            this.reset();
            this.menuSearchProfile = true;
            let profiles = [];
            let profilesWithBoxName = [];
            if (this.selectedProfile.pseudo === "") {
                this.menuSearchProfile = false;
                return;
            }
            profiles = await this.getProfiles(this.selectedProfile.pseudo, '');
            profilesWithBoxName = await this.getProfiles(this.selectedProfile.pseudo, 'Box Name');

            const uniqueProfiles = profiles.filter(p1 => !profilesWithBoxName.some(p2 => p1.pseudo === p2.pseudo));
            const uniqueProfilesWithBoxName = profilesWithBoxName.filter(p2 => !uniqueProfiles.some(item => p2.pseudo === item.pseudo));

            if (uniqueProfiles && uniqueProfiles.length > 0) {
                this.selectedItemsPseudo = uniqueProfiles.filter(item => item.pseudo.toLowerCase().includes(this.selectedProfile.pseudo.toLowerCase()));
            }
            if (uniqueProfilesWithBoxName && uniqueProfilesWithBoxName.length > 0) {
                this.selectedItemsBoxName = uniqueProfilesWithBoxName.filter(item => item.pseudo.toLowerCase().includes(this.selectedProfile.pseudo.toLowerCase()));
            }
            console.log(this.selectedItemsPseudo);
            console.log(this.selectedItemsBoxName);
        },
        async getWodsWithLimit() {
            try {
                return await getWodsWithLimit(this.home.elementCountPerPage);
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err);
                return [];
            }
        },
    }
};
</script>

<style>
.mdi-weight-lifter::before {
    color: #E74C3C;
}
.mdi-account-box::before {
    color: #F1C40F;
}
.mdi-calendar-month::before {
    color: #2196f3;
}
.mdi-calendar-outline::before {
    color: #16A085;
}
</style>