<template>
    <v-dialog v-model="dialog" persistent fullscreen>
        <template v-slot:activator="{ props }">
            <v-btn v-if="this.program === -1" style="background-color: #16A08590" size="large" class="px-4" append-icon="mdi-plus-circle" variant="text" type="submit" v-bind="props">Wods</v-btn>
            <v-btn v-else v-bind="props" type="submit" prepend-icon="mdi-plus-box" variant="tonal">Ajouter un WOD</v-btn>
        </template>
        <v-card>
            <v-card-title class="pt-6">
                <span class="my-title">Nouveau WOD</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="auto" style="min-width: 400px">
                        <v-text-field label="Nom du WOD" required v-model="this.wod.name"></v-text-field>
                    </v-col>
                    <v-col cols="auto" style="min-width: 400px">
                        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition">
                            <template v-slot:activator="{ props }">
                                <v-text-field v-bind="props" label="Type de WOD" readonly v-model="this.wod.type"></v-text-field>
                            </template>
                            <v-list>
                                <v-list-item v-for="type in types" :key="type" v-on:click="this.wod.type = type.title; this.menu = false">
                                    <v-list-item-title>{{ type.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="auto">
                        <h2>Date de réalisation</h2><VueDatePicker style="margin-top: 10px;" v-model="this.wod.date" :enable-time-picker="false" :format="format"/>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                        <NewScoreWodDialog v-if="this.wod.name" @add-score-in-current-wod="this.addScoreInCurrentWod"></NewScoreWodDialog>
                    </v-col>
                    <v-col cols="auto">
                        <ScoreWodDialog v-if="this.wod.name" :active-by-button="true" :wod="this.wod" :color-score-button="my_green()"></ScoreWodDialog>
                    </v-col>
                </v-row>
                <v-row v-if="!this.loading" justify="center">
                    <v-col class="text-left flex-grow-1">
                        <TextFieldMenuEdit @components-event="sendDataToBDD" :props-components-list="this.componentsList" :props-wod="this.wod"></TextFieldMenuEdit>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#2196f390" variant="elevated" @click="this.closeDialog">
                    Fermer
                </v-btn>
                <v-btn color="#F1C40F90" variant="elevated" @click="this.createWod">
                    Créer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>
import MySnackBar from "@/components/MySnackBar.vue";
import TextFieldMenuEdit from "@/components/TextFieldMenuEdit.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import NewScoreWodDialog from "@/components/NewScoreWodDialog.vue";
import ScoreWodDialog from "@/components/ScoreWodDialog.vue";
import {deleteWod, getWodById, insertNewWod} from "@/db/wods";
import {my_green} from "@/utils/enums";

export default {
    name: "NewWodView",
    emits: ['createDirectoryDialogClosed', 'refresh'],
    components: {
        ScoreWodDialog,
        NewScoreWodDialog,
        VueDatePicker,
        TextFieldMenuEdit,
        MySnackBar,
    },
    data: () => ({
        dialog: false,
        menu: false,
        menuMovements: false,
        menuCategories: false,
        showTitles: false,
        editMode: false,
        loading: true,
        types: [
            {id: 0, title: "AMRAP"},
            {id: 1, title: "EMOM"},
            {id: 2, title: "HALTERO"},
            {id: 3, title: "FOR TIME"},
            {id: 4, title: "RENFO"},
            {id: 5, title: "FORCE"},
            {id: 6, title: "VOLUME"},
            {id: 6, title: "MULTIPLE"},
        ],
        wod: {
            name: "",
            type: "",
            dir: "",
            date: new Date(),
            titles: [],
            descriptions: [],
            scores: [],
            isPr: false,
            note: "",
        },
        wodId: -1,
        componentsList: [],
        format: (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
    }),
    props: {
        currentDir: {
            type: String,
            required: true,
        },
        program: {
            id: Number,
            required: true,
        },
        createWodCallback: {
            type: Function,
            required: false
        },
    },
    computed: {
        editorValue: {
            get() {
                return this.initialValue;
            },
            set(newValue) {
                this.initialValue = newValue;
            },
        },
    },
    watch: {
        async dialog(newValue) {
            if (newValue && this.wod.name === "") {
                await this.handleDialogOpen();
            } else if (newValue && this.wod.name !== "") {
                this.wod.date = new Date(this.wod.date);
                this.loading = false;
            }
        }
    },
    methods: {
        my_green() {
            return my_green
        },
        addScoreInCurrentWod(score) {
            const newScore = {
                score: score.score,
                scoreType: score.score_type,
                date: score.date,
                pseudo: score.pseudo,
            };
            console.log(newScore);
            if (!this.wod.scores) {
                this.wod.scores = [];
            }
            this.wod.scores.push(newScore);
            console.log(this.wod.scores);
        },
        async sendDataToBDD()
        {
            try {
                this.updatePosition();
                this.wod.dir = this.currentDir;
                this.wod.descriptions = this.componentsList.filter((item) => item.type !== "Titre");
                this.wod.titles = this.componentsList.filter((item) => item.type === "Titre");

                if (this.wodId !== -2) {
                    await deleteWod(this.wodId);
                }
                if (!(typeof this.createWodCallback === 'function')) {
                    await insertNewWod(this.wod, this.program);
                } else {
                    try {
                        await this.createWodCallback(this.wod);
                    } catch (err) {
                        console.error(err);
                        this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
                        return;
                    }
                }
                this.$refs.mySnackbarRef.active("Nouvequ WOD crée", "success")
            } catch (err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        },
        async handleDialogOpen() {
            if (this.wodId !== -1) {
                try {
                    this.wod = await getWodById(this.wodId);
                    this.wod.date = new Date(this.wod.date);
                    console.log(this.wod);
                    this.loading = false;
                } catch (err) {
                    console.error(err);
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
                }
            } else {
                this.loading = false;
            }
        },
        resetWod() {
            this.wod = {
                name: "",
                type: "",
                dir: "",
                date: new Date(),
                titles: [],
                descriptions: [],
                scores: [],
                isPr: false,
                note: "",
            };
            console.log(this.wod);
            this.wodId = -1;
            this.loading = true;
            this.componentsList = [];
        },
        updatePosition() {
            for (let i = 0; i < this.componentsList.length; i++) {
                this.componentsList[i].position = i;
            }
        },
        async createWod() {
            await this.sendDataToBDD();
            this.$emit("refresh");
            this.closeDialog()
        },
        closeDialog() {
            this.dialog = false;
            this.resetWod();
        },
    }
}

</script>

<style scoped>
.my-title {
    font-family: 'Balsamiq', sans-serif;
    font-size: 32px;
    font-weight: bold;
}
</style>