<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "MyProgressCircular"
})
</script>

<template>
    <div class="text-center">
        <v-progress-circular
            indeterminate
        ></v-progress-circular>
    </div>
</template>

<style scoped>

</style>