<template>
    <h2 style="margin-top: 20px">Wod</h2>
    <v-layout style="margin-bottom: 75px; margin-left: 20px; margin-top: 10px">
        <v-main style="background-color: transparent">
            <v-row>
                <v-col cols="auto" class="divider-column">
                    <div class="vertical-divider"></div>
                </v-col>
                <v-col>
                    <v-list v-for="(component, index) in componentsList" :key="component" style="background-color: transparent">
                        <v-list-item style="margin: 0; padding: 0">
                            <v-row v-if="component.type === 'Description'" style="max-width: 50%">
                                <v-col :class="'ml-' + component.style.indent" cols="2">
                                    <v-textarea
                                        v-model="component.text"
                                        auto-grow
                                        single-line
                                        density="compact"
                                        variant="outlined"
                                        label="Description"
                                        rows="2"
                                        style="min-width: 1000px"
                                        hide-details
                                        bg-color="#2196f390"
                                    >
                                        <template v-slot:prepend>
                                            <v-menu>
                                                <template v-slot:activator="{ props }">
                                                    <v-icon v-bind="props" icon="mdi-dots-vertical" center></v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'delete')" variant="text" @click="deleteItemFromList(index)">
                                                            <v-icon icon="mdi-trash-can-outline" size="large" start></v-icon>
                                                            Supprimer
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'increase')" variant="text" @click="increaseItem(component)">
                                                            <v-icon icon="mdi-format-indent-increase" size="large" start></v-icon>
                                                            Indenter
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'decrease')" variant="text" @click="decreaseItem(component)">
                                                            <v-icon icon="mdi-format-indent-decrease" size="large" start></v-icon>
                                                            Desindenter
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'up')" variant="text" @click="upItemInList(index)">
                                                            <v-icon icon="mdi-arrow-up-bold-box-outline" size="large" start></v-icon>
                                                            Remonter
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'down')" variant="text" @click="downItemInList(index)">
                                                            <v-icon icon="mdi-arrow-down-bold-box-outline" size="large" start></v-icon>
                                                            Descendre
                                                        </v-btn>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row v-if="component.type === 'Titre'">
                                <v-col :class="'ml-' + component.style.indent" v-bind:cols="9" v-bind:sm="6">
                                    <v-textarea
                                        v-model="component.text"
                                        auto-grow
                                        single-line
                                        density="compact"
                                        variant="outlined"
                                        label="Titre"
                                        rows="1"
                                        style="min-width: 500px"
                                        hide-details
                                        bg-color="#F1C40F90"
                                    >
                                        <template v-slot:prepend>
                                            <v-menu>
                                                <template v-slot:activator="{ props }">
                                                    <v-icon v-bind="props" icon="mdi-dots-vertical" center></v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'delete')" variant="text" @click="deleteItemFromList(index)">
                                                            <v-icon icon="mdi-trash-can-outline" size="large" start></v-icon>
                                                            Supprimer
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'increase')" variant="text" @click="increaseItem(component)">
                                                            <v-icon icon="mdi-format-indent-increase" size="large" start></v-icon>
                                                            Indenter
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'decrease')" variant="text" @click="decreaseItem(component)">
                                                            <v-icon icon="mdi-format-indent-decrease" size="large" start></v-icon>
                                                            Desindenter
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'up')" variant="text" @click="upItemInList(index)">
                                                            <v-icon icon="mdi-arrow-up-bold-box-outline" size="large" start></v-icon>
                                                            Remonter
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn :disabled="this.checkDisabledItem(index, component, 'down')" variant="text" @click="downItemInList(index)">
                                                            <v-icon icon="mdi-arrow-down-bold-box-outline" size="large" start></v-icon>
                                                            Descendre
                                                        </v-btn>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row v-if="component.type === 'Saut de ligne'" style="align-items: center">
                                <v-col>
                                    <template v-slot:default>
                                        <v-menu>
                                            <template v-slot:activator="{ props }">
                                                <v-icon v-bind="props" icon="mdi-dots-vertical" center></v-icon>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-btn :disabled="this.checkDisabledItem(index, component, 'delete')" variant="text" @click="deleteItemFromList(index)">
                                                        <v-icon icon="mdi-trash-can-outline" size="large" start></v-icon>
                                                        Supprimer
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn :disabled="this.checkDisabledItem(index, component, 'up')" variant="text" @click="upItemInList(index)">
                                                        <v-icon icon="mdi-arrow-up-bold-box-outline" size="large" start></v-icon>
                                                        Remonter
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn :disabled="this.checkDisabledItem(index, component, 'down')" variant="text" @click="downItemInList(index)">
                                                        <v-icon icon="mdi-arrow-down-bold-box-outline" size="large" start></v-icon>
                                                        Descendre
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>
                                </v-col>
                                <v-col cols="auto" style="min-width: 400px">
                                    <v-divider :thickness="3" class="border-opacity-50"></v-divider>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
            <v-row style="max-width: 50%">
                <v-col>
                    <v-textarea
                        v-model="this.wod.note"
                        auto-grow
                        single-line
                        density="compact"
                        variant="outlined"
                        label="Note"
                        rows="3"
                        style="min-width: 500px"
                        hide-details
                        bg-color="#16A08590"
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-main>
    </v-layout>
    <v-row>
        <v-col>
            <v-menu v-model="openMenuNewItem" :close-on-content-click="false" transition="scale-transition">
                <template v-slot:activator="{ props }">
                    <v-btn prepend-icon="mdi-plus-box" v-bind="props" variant="tonal" v-model="selectedNewItem">Ajouter un element</v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="item in items" :key="item" v-on:click="this.selectedNewItem = item; this.openMenuNewItem = false; this.addNewComponent()">
                        <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>

import {comparePositions} from "@/utils/strings";

export default {
    name: "TextFieldMenuEdit",
    props: {
        propsWod: {
            type: Object,
            required: true
        },
        propsComponentsList: {
            type: Array,
            required: true,
        }
    },
    data: () => ({
        openMenuNewItem: false,
        selectedNewItem: "",
        items: ["Titre", "Description", "Saut de ligne"],
        componentsList: [],
        lastIndent: 0,
        wod: null,
    }),
    created() {
        this.wod = this.propsWod;
        this.componentsList = this.propsComponentsList;

        console.log(this.wod);
        if (this.wod.titles.length === 0) {
            this.selectedNewItem = "Titre";
            this.addNewComponent();
        } else {
            for (const title of this.wod.titles) {
                this.addNewComponentWithParams(title.text, "Titre", title.position, title.style);
            }
        }

        if (this.wod.descriptions.length === 0) {
            this.selectedNewItem = "Description";
            this.addNewComponent();
        } else {
            for (const desc of this.wod.descriptions) {
                this.addNewComponentWithParams(desc.text, "Description", desc.position, desc.style);
            }
        }

        this.componentsList.sort(comparePositions);
    },
    methods: {
        addNewComponent() {
            this.componentsList.push({
                text: "",
                type: this.selectedNewItem,
                position: 0,
                style: {
                    indent: this.selectedNewItem === "Titre" ? 0 : this.lastIndent
                }
            });
        },
        addNewComponentWithParams(text, type, position, style) {
            this.componentsList.push({
                text: text,
                type: type,
                position: position,
                style: style
            });
        },
        deleteItemFromList(index) {
            this.componentsList.splice(index, 1)
        },
        upItemInList(index) {
            if (index === 0) {
                return;
            }
            const item = this.componentsList.splice(index, 1);
            this.componentsList.splice(index - 1, 0, item[0]);
        },
        downItemInList(index) {
            if (index === this.componentsList.length - 1) {
                return
            }
            const item = this.componentsList.splice(index, 1);
            this.componentsList.splice(index + 1, 0, item[0]);
        },
        increaseItem(component) {
            component.style.indent += 4;
            this.lastIndent = component.style.indent;
        },
        decreaseItem(component) {
            component.style.indent -= 4;
            this.lastIndent = component.style.indent
        },
        checkDisabledItem(index, component, type) {
            if (type === "increase") {
                return component.style.indent >= 16;
            } else if (type === "decrease") {
                return component.style.indent === 0;
            } else if (type === "up") {
                return index === 0;
            } else if (type === "down") {
                return index === this.componentsList.length - 1;
            }
            return false;
        },
    },
}
</script>

<style>
.max-width {
    max-width: 100%;
}

@media (min-width: 0px) {
    .max-width {
        max-width: 100%;
    }
}

@media (min-width: 600px) {
    .max-width {
        max-width: 600px;
    }
}

@media (min-width: 960px) {
    .max-width {
        max-width: 960px;
    }
}

@media (min-width: 1264px) {
    .max-width {
        max-width: 75%;
    }
}
.vertical-divider {
    width: 2px;
    height: 100%;
    border-radius: 50px;
    background-color: #e0e0e0;
}
.divider-column {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>