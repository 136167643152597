<template>
    <div v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
        <v-btn>{{ this.id }}</v-btn>
        <h2>{{ this.wod.name }}</h2>
        <h3>{{ getDateWithBasicFormat(this.wod.date) }}</h3>
        <h3>{{ this.wod.type }}</h3>
        <v-list>
            <v-list-item v-for="(item, index) in wodFlow" :key="index">
                <v-list-item :style="{ paddingLeft: item.style.indent * 3 + 'px' }">
                    <v-list-item-title
                        :class="item.type === 'title' ? 'wod-description' : 'wod-title'"
                        v-html="item.text"
                    ></v-list-item-title>
                </v-list-item>
            </v-list-item>
        </v-list>
    </div>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>
import {getWodById} from "@/db/wods";
import {getDateWithBasicFormat} from "../utils/strings";
import MySnackBar from "@/components/MySnackBar.vue";

export default {
    name: "WodComponent",
    components: {MySnackBar},
    mounted() {
        this.id = this.$route.params.id;
    },
    async created() {
        try {
            this.isLoading = true;
            this.wod = await getWodById(this.$route.params.id);
            this.createWodFlow();
            this.isLoading = false;
        } catch (err) {
            this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            console.error(err);
        }
    },
    methods: {
        getDateWithBasicFormat,
        createWodFlow() {
            if (this.wod) {
                console.log(this.wod)
                const allItems = [
                    ...this.wod.descriptions.map(description => ({ ...description, type: 'description' })),
                    ...this.wod.titles.map(title => ({ ...title, type: 'title' }))
                ];
                const sortedItems = allItems.sort((a, b) => a.position - b.position);
                const descriptionsAndTitles = sortedItems.map(item => {
                    item.text = item.text.replace(/\n/g, "<br>")
                    return item;
                });
                console.log(descriptionsAndTitles);
                this.wodFlow = descriptionsAndTitles;
            }
        }
    },
    data: () => ({
        id: 0,
        wod: null,
        wodFlow: [],
        isLoading: true,
    })
}
</script>

<style>
.wod-title {
    background-color: #5DADE2;
    padding: 10px;
    border-radius: 5px;
}

.wod-description {
    background-color: #F1C40F;
    padding: 10px;
    border-radius: 5px;
}
</style>