import axios from "axios";
import Cookies from "js-cookie";
import {url} from "@/db/db";
import {CONNECTION_TIMEOUT_MS, REGISTRATION_TIMEOUT_MS} from "@/utils/enums";

export function createUser(surname, firstname, age, mail, password ) {
    return new Promise((resolve, reject) => {
        const user = {
            surname: surname,
            firstname: firstname,
            age: age,
            mail: mail,
            password: password
        }

        const urlCreateUser = url + 'create-user'
        axios.post(urlCreateUser, user, {
            timeout: REGISTRATION_TIMEOUT_MS,
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function connectionUser(mail, password)
{
    return new Promise((resolve, reject) => {
        const user = {
            mail: mail,
            password: password
        }
        const urlGetUser = url + 'connection-user'
        console.log(urlGetUser)

        const token = Cookies.get('token')

        axios.post(urlGetUser, user, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            timeout: CONNECTION_TIMEOUT_MS,
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function checkAccess()
{
    return new Promise((resolve, reject) => {
        const urlProtected = url + 'protected'
        console.log("protected : " + urlProtected)

        const token = Cookies.get('token')

        axios.get(urlProtected, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export function getUserFromToken()
{
    return new Promise((resolve, reject) => {
        const urlProtected = url + 'get-user-from-token'

        const token = Cookies.get('token')
        const dataToken = {
            token: token
        }

        axios.post(urlProtected, dataToken, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}