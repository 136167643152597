
export const my_blue = "#2196f390"
export const my_green = "#16A08590"
export const my_yellow = "#F1C40F90"
export const my_red = "#E74C3C90"
export const my_purple = "#9B59B690"
export const my_orange = "#F39C1290"

export const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
];

export const dayOnWeek = [
    "Lun",
    "Mar",
    "Mer",
    "Jeu",
    "Ven",
    "Sam",
    "Dim",
];

export function getBasicDateFormatWithoutDay(currentDate) {
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return `${month} ${year}`
}

export const CONNECTION_TIMEOUT_MS = 5000
export const REGISTRATION_TIMEOUT_MS = 5000