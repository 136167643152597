<template>
    <v-row class="ma-0">
        <v-dialog v-model="dialog" width="85%">
            <template v-slot:activator="{ props }">
                <v-btn
                    :style="{
                        backgroundColor: this.colorScoreButton,
                        borderRadius: '10px',
                        padding: '10px'
                    }"
                    append-icon="mdi-arrow-right"
                    v-bind="props">
                    <h4>Scores</h4>
                </v-btn>
            </template>
            <v-card>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" :style="{backgroundColor: my_purple(), borderRadius: '10px'}">
                            <h2>{{ wod.name }}</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text style="padding: 27px">
                    <v-row>
                        <v-col cols="3">
                            <v-btn elevation="0" :style="{backgroundColor: my_purple(), borderRadius: '10px'}"><h3>Pseudo</h3></v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn elevation="0" :style="{backgroundColor: my_purple(), borderRadius: '10px'}"><h3>Score</h3></v-btn>
                        </v-col>
                        <v-col cols="3">
                            <v-btn elevation="0" :style="{backgroundColor: my_purple(), borderRadius: '10px'}"><h3>Type de score</h3></v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn elevation="0" :style="{backgroundColor: my_purple(), borderRadius: '10px'}"><h3>Date de réalisation</h3></v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(score, index) in scores" :key="score.id" style="align-items: center; margin-top: 0">
                        <v-col cols="3">
                            <h3 style="margin-left: 10px">{{ score.pseudo }}</h3>
                        </v-col>
                        <v-col cols="2">
                            <h3 style="margin-left: 10px">{{ score.score }}</h3>
                        </v-col>
                        <v-col cols="3">
                            <h3 style="margin-left: 10px">{{ score.scoreType }}</h3>
                        </v-col>
                        <v-col cols="4">
                            <h3 style="margin-left: 10px">{{ getDateWithBasicFormatDateParam(new Date(score.date)) }}</h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" v-if="this.activeByButton === true">
                            <v-btn variant="text" icon="mdi-close-box" elevation="0" style="border-radius: 5px" v-on:click="this.removeScore(index)"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#2196f390" variant="elevated" v-on:click="this.closeDialog">
                        Fermer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {getDateWithBasicFormatDateParam} from "../utils/strings";
import MySnackBar from "@/components/MySnackBar.vue";
import {mapState} from "vuex";
import {my_blue, my_green, my_purple, my_red, my_yellow} from "@/utils/enums";

export default {
    name: "ScoreWodDialog",
    components: {MySnackBar},
    emits: ["refreshScoreWodView", "removeScore"],
    computed: {
        ...mapState(['user']),
    },
    props: {
        wod: null,
        activeByButton: {
            type: Boolean,
            required: true
        },
        colorScoreButton: {
            type: String,
            required: true,
        }
    },
    watch: {
        async dialog(newValue) {
            if (newValue) {
                await this.handleDialogOpen()
            }
        }
    },
    data: () => ({
        dialog: false,
        myProfileId: localStorage.getItem('profileId'),
        scores: [],
    }),
    methods: {
        my_red() {
            return my_red
        },
        my_yellow() {
            return my_yellow
        },
        my_blue() {
            return my_blue
        },
        my_green() {
            return my_green
        },
        my_purple() {
            return my_purple
        },
        getDateWithBasicFormatDateParam,
        async handleDialogOpen() {
            this.scores = this.wod.scores;
            if (!this.scores) {
                this.scores = [];
            }
            console.log(this.scores);
        },
        async removeScore(index) {
            try {
                this.scores.splice(index, 1);
            } catch (err) {
                console.log(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion")
            }
        },
        closeDialog() {
            this.dialog = false;
        }
    }
}
</script>

<style scoped>

</style>