<template>
   <v-container fluid>
       <v-row justify="center" class="mb-5">
           <h1 class="h1-custom-size">{{ $t("login_page") }}</h1>
       </v-row>
       <v-row v-if="alert">
           <v-alert :color="color" icon="$info" :title="message" max-width="600" class="mx-auto"></v-alert>
       </v-row>
       <v-row justify="center">
           <v-col :cols="12" :sm="10" :md="8" :lg="6">
               <v-card class="mx-auto px-6 py-8" max-width=600 elevation="3">
                   <v-form v-model="form" @submit.prevent="onSubmit">
                       <v-text-field variant="solo" v-model="email" :readonly="loading" :rules="[required]" class="mb-2" clearable :label="$t('mail_address')"></v-text-field>
                       <v-text-field variant="solo" type="password" v-model="password" :readonly="loading" :rules="[required]" clearable :label="$t('password')" :placeholder="$t('enter_password')"></v-text-field>
                       <br>
                       <v-btn :loading="loading" block color="info" size="large" type="submit" variant="elevated">
                           {{ $t("button_connection") }}
                       </v-btn>
                   </v-form>
               </v-card>
           </v-col>
       </v-row>
       <v-row justify="center">
          <v-col class="flex-grow-0">
              <v-btn :disabled="loading" block color="grey-lighten-2" size="large" type="submit" variant="elevated" v-on:click="onRegistration">
                  {{ $t("button_registration") }}
              </v-btn>
          </v-col>
       </v-row>
   </v-container>
</template>

<script>
import {connectionUser} from "@/db/connection";
import {mapMutations, mapState} from "vuex";
import {GetProfileID} from "@/db/profile";

export default {
    inject: ['$i18n'],
    name: "ConnexionView",
    computed: {
        ...mapState(['user']),
    },
    methods: {
        ...mapMutations(['setProfileId']),
        onRegistration() {
            this.$router.push('/registration')
        },
        async onSubmit() {
          if (!this.form) {
              this.showAlert("missing_data", "error")
              return
          }

          this.loading = true;

          try {
              this.showAlert("connection_in_progress", "warning")
              const response = await connectionUser(this.email, this.password)
              this.showAlert("successful_connection", "success")

              const expirationDate = new Date()
              expirationDate.setDate(expirationDate.getDate() + 10)
              this.$cookies.set('token', response.token, expirationDate)

              const profileId = await GetProfileID();
              localStorage.setItem('profileId', profileId + '');
              this.$router.push('/')
              this.loading = false;
          } catch (error) {
              this.showAlert("connection_error", "error")
              this.loading = false;
          }
        },
        required (v) {
            return !!v || this.$t("field_required")
        },

        showAlert(message, color) {
            this.color = color
            this.alert = true
            this.message = this.$t(message)
        }
    },
    data: () => ({
        form: false,
        email: null,
        password: null,
        loading: false,
        alert: false,
        message: '',
        color: ''
    }),
}
</script>