<template>
    <v-dialog v-model="dialog" width="1024">
        <template v-slot:activator="{ props }">
            <v-btn
                :style="{
                        backgroundColor: my_yellow(),
                        borderRadius: '10px',
                        padding: '10px'
                    }"
                append-icon="mdi-plus-box"
                v-bind="props">
                <h4>Ajouter un score</h4>
            </v-btn>
        </template>
        <v-card>
            <v-card-title style="margin-left: 10px; margin-top: 10px">
                <v-row style="padding: 15px">
                    <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                        <h2>Enregistrer un nouveau score</h2>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <h2>Date de réalisation</h2>
                    <VueDatePicker style="margin-bottom: 20px; margin-top: 10px; margin-left: 10px; max-width: 30vw" v-model="this.newScore.date" :enable-time-picker="false" :format="format"/>
                    <h2 style="margin-bottom: 10px">Athlète</h2>
                    <v-text-field
                        label="Pseudo"
                        v-model="this.newScore.pseudo"
                        variant="solo"
                        density="compact"
                        hide-details
                        single-line
                        style="margin-bottom: 20px; margin-left: 10px"
                    ></v-text-field>
                    <h2 style="margin-bottom: 10px">Score</h2>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Score"
                                v-model="this.newScore.score"
                                variant="solo"
                                density="compact"
                                hide-details
                                single-line
                                style="margin-bottom: 10px; margin-left: 10px"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-menu v-model="menuOpen" :close-on-content-click="false">
                                <template v-slot:activator="{ props }">
                                    <v-btn :color="my_green()" v-bind="props" style="margin-bottom: 20px"><h3>{{ this.newScore.score_type }}</h3></v-btn>
                                </template>
                                <v-list class="scrollable-list">
                                    <v-list-item v-for="item in scoreType" :key="item" @click="selectItem(item)">
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#2196f390" variant="elevated" @click="dialog = false">
                    Fermer
                </v-btn>
                <v-btn color="#F1C40F90" variant="elevated" @click="dialog = false" v-on:click="InsertNewScore">
                    Ajouter
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>
import {my_green, my_yellow} from "@/utils/enums";
import VueDatePicker from "@vuepic/vue-datepicker";
import MySnackBar from "@/components/MySnackBar.vue";

export default {
    name: "NewScoreWodDialog",
    components: {MySnackBar, VueDatePicker},
    emits:['addScoreInCurrentWod'],
    methods: {
        my_green() {
            return my_green
        },
        my_yellow() {
            return my_yellow
        },
        async InsertNewScore() {
            try {
                console.log(this.newScore.score);
                console.log(this.newScore.score_type);
                console.log(this.newScore.date);
                this.$emit("addScoreInCurrentWod", this.newScore);
            } catch (err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
            this.newScore.pseudo = '';
            this.newScore.score_type = 'Reps';
            this.newScore.date = new Date();
            this.newScore.score = '';
        }
    },
    data: () => ({
        dialog: false,
        newScore: {
            score: "",
            score_type: "Reps",
            pseudo: "",
            date: new Date(),
        },
        scoreType: ["Reps", "Cals", "Kg", "Distance", "Temps"],
        menuOpen: false,
        format: (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
        selectItem(item) {
            this.newScore.score_type = item;
            this.menuOpen = false;
        },
        beforeMount() {
            this.newScore = this.wod.id;
        }
    }),
}
</script>

<style>
.scrollable-list {
    max-height: 250px;
    overflow-y: auto;
}
</style>