<template>
    <div class="ma-3">
        <v-row style="margin-right: 20px">
            <v-col cols="auto">
                <h2 class="ma-4">Derniers WODs réalisés</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <v-select
                    v-model="this.home.elementCountPerPage"
                    variant="solo"
                    hide-details
                    single-line
                    density="compact"
                    :items="elementsCountPerPage"
                    @update:model-value="this.refresh"
                ></v-select>
            </v-col>
        </v-row>
        <div>
            <v-row class="mx-4" v-for="(wod, index) in this.wods" :key="index" style="align-items: center">
                <v-col cols="auto">
                    <h4>{{ index + 1 }}.</h4>
                </v-col>
                <v-col cols="auto" v-on:click="this.showWodDetails(wod)">
                    <h4>{{ wod.name }}</h4>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <div style="border-radius: 10px; padding: 4px 10px 4px 10px; background-color: #2196f390">
                        {{ wod.type }}
                    </div>
                </v-col>
                <v-col cols="auto">
                    {{ getDateWithBasicFormatDateParam(new Date(wod.date)) }}
                </v-col>
            </v-row>
            <div v-if="!this.wods || this.wods.length === 0">
                <h3 style="opacity: 0.7; margin-left: 30px">Aucun WOD</h3>
            </div>
        </div>
    </div>
    <WodDialog ref="wodDialog"></WodDialog>
    <MySnackBar ref="mySnackBarRef"></MySnackBar>
</template>

<script>
    import {mapState} from "vuex";
    import MySnackBar from "@/components/MySnackBar.vue";
    import {getDateWithBasicFormatDateParam} from "../utils/strings";
    import WodDialog from "@/components/WodDialog.vue";

    export default {
        components: {WodDialog, MySnackBar},
        computed: {
            ...mapState(['home']),
        },
        props: {
            getWods: {
                type: Function,
                required: true,
            }
        },
        async created() {
            this.wods = await this.getWods();
        },
        data() {
            return {
                elementsCountPerPage: [1, 3, 5, 10],
                wods: [],
            }
        },
        methods: {
            getDateWithBasicFormatDateParam,
            async refresh() {
                console.log(this.home.elementCountPerPage);
                this.wods = await this.getWods();
            },
            showWodDetails(item) {
                this.$refs.wodDialog.wodId = item.id;
                this.$refs.wodDialog.wodDialog = true;
            },
        }
    }
</script>

<style scoped>

</style>