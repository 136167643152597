<template>
    <div>
        <v-layout>
            <v-main style="height: auto; min-height: 100vh">
                <div>
                    <BackButton style="margin: 10px"></BackButton>
                    <h1 style="text-align: center;">
                        <span style="background-color: #F1C40F90; padding: 10px; border-radius: 10px">Nouvelle programmation</span>
                    </h1>
                    <v-row style="justify-content: end; margin-right: 10px">
                        <v-col cols="auto">
                            <v-menu>
                                <template v-slot:activator="{ props }">
                                    <v-btn v-bind="props" prepend-icon="mdi-clock" :style="{backgroundColor: this.getStateColor(), padding: '10px', borderRadius: '10px'}">{{ this.newProgram.state }}</v-btn>
                                </template>
                                <v-list>
                                    <v-list-item :key="0">
                                        <v-btn size="small" :color="states[0].color" v-on:click="this.newProgram.state = states[0].title">{{ states[0].title }}</v-btn>
                                    </v-list-item>
                                    <v-list-item :key="1">
                                        <v-btn size="small" :color="states[1].color" v-on:click="this.newProgram.state = states[1].title">{{ states[1].title }}</v-btn>
                                    </v-list-item>
                                    <v-list-item :key="2">
                                        <v-btn size="small" :color="states[2].color" v-on:click="this.newProgram.state = states[2].title">{{ states[2].title }}</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                   <v-row style="margin-left: 5px">
                       <v-col cols="6">
                           <v-row style="margin-left: 5px; margin-right: 10px">
                               <v-col cols="8">
                                   <h2 style="margin-bottom: 5px; margin-top: 30px">Titre de la programmation</h2>
                                   <v-text-field label="Titre" v-model="this.newProgram.title"></v-text-field>
                               </v-col>
                               <v-spacer></v-spacer>
                           </v-row>
                           <v-row style="margin-left: 5px">
                               <v-col cols="8">
                                   <h2 style="margin-bottom: 10px; margin-top: 5px">Type</h2>
                                   <v-text-field label="WOD / Force / Renfo" v-model="this.newProgram.type"></v-text-field>
                               </v-col>
                           </v-row>
                           <v-row style="margin-left: 5px">
                               <v-col cols="9">
                                   <h2 style="margin-bottom: 10px; margin-top: 5px">Description</h2>
                                   <v-textarea
                                       v-model="this.newProgram.description"
                                       auto-grow
                                       single-line
                                       density="compact"
                                       label="Description"
                                       rows="3"
                                       style="min-width: 500px"
                                       hide-details
                                   ></v-textarea>
                               </v-col>
                           </v-row>
                           <v-row style="margin-left: 5px">
                               <SearchFolder @sendSelectedFolder="this.refreshSelectedFolder"></SearchFolder>
                           </v-row>
                           <v-row style="margin-left: 5px">
                               <v-col cols="auto">
                                   <h2>Date de debut</h2><VueDatePicker style="margin-top: 10px;" v-model="this.newProgram.startDate" :enable-time-picker="false" :format="format"/>
                               </v-col>
                               <v-col cols="auto">
                                   <h2>Date de fin</h2><VueDatePicker style="margin-top: 10px;" v-model="this.newProgram.endDate" :enable-time-picker="false" :format="format"/>
                               </v-col>
                           </v-row>
                       </v-col>
                       <v-col cols="6">
                           <NewWodView @refresh="this.refresh" ref="newWodView" current-dir="Programmations" :program="-1" :create-wod-callback="this.callback"></NewWodView>
                           <v-row style="margin: 10px;" v-for="(wod, index) in this.wods" :key="index">
                               <v-card :color="my_blue()" width="800px">
                                   <div class="d-flex flex-no-wrap justify-space-between">
                                       <div>
                                           <v-card-title class="text-h5">
                                               {{ wod.name }}
                                           </v-card-title>
                                           <v-card-subtitle>
                                               {{ wod.type }} - {{ getDateWithBasicFormatDateParam(new Date(wod.date)) }}
                                           </v-card-subtitle>
                                           <v-card-actions>
                                               <v-btn variant="outlined" size="small" v-on:click="this.openWod(wod)">
                                                   Ouvrir
                                               </v-btn>
                                               <v-btn variant="outlined" size="small" v-on:click="this.modifyWod(wod, index)">
                                                   Modifier
                                               </v-btn>
                                               <v-spacer></v-spacer>
                                               <v-btn variant="outlined" size="small" v-on:click="this.deleteWod(index)">
                                                   Supprimer
                                               </v-btn>
                                           </v-card-actions>
                                       </div>
                                   </div>
                               </v-card>
                           </v-row>
                       </v-col>
                   </v-row>
                    <v-spacer></v-spacer>
                    <v-row style="text-align: center">
                        <v-col>
                            <v-btn style="margin: 10px;" :color="my_green()" v-on:click="this.createProgram">Créer</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-main>
        </v-layout>
        <WodDialog ref="wodDialog"></WodDialog>
        <MySnackBar ref="mySnackbarRef"></MySnackBar>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import BackButton from "@/components/BackButton.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import NewWodView from "@/views/NewWodView.vue";
import {getDateWithBasicFormatDateParam} from "../utils/strings";
import {my_blue, my_green} from "@/utils/enums";
import WodDialog from "@/components/WodDialog.vue";
import {addNewProgram} from "@/db/programs";
import {insertNewWod} from "@/db/wods";
import SearchFolder from "@/components/SearchFolder.vue";
import MySnackBar from "@/components/MySnackBar.vue";

export default defineComponent({
    name: "NewProgramView",
    components: {MySnackBar, SearchFolder, WodDialog, NewWodView, VueDatePicker, BackButton},
    data: () => ({
        newProgram: {
            title: "",
            type: "",
            state: "Pas commencée",
            description: "",
            startDate: new Date(),
            endDate: new Date(),
            directory_id: 0,
        },
        refreshSearchFolder: 0,
        modifyWodIndex: -1,
        wods: [],
        states: [
            {title: "Pas commencée", color: "#16A08590"},
            {title: "En cours", color: "#F1C40F90"},
            {title: "Terminée", color: "#E74C3C90"},
        ],
        format: (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }
    }),
    methods: {
        my_green() {
            return my_green
        },
        my_blue() {
            return my_blue
        },
        getDateWithBasicFormatDateParam,
        getStateColor() {
            for(let i = 0; i < this.states.length; ++i) {
                if (this.newProgram.state.toLowerCase() === this.states[i].title.toLowerCase()) {
                    return this.states[i].color;
                }
            }
            return this.states[2].color;
        },
        callback(newWod) {
            console.log(newWod.dir);
            console.log(newWod.descriptions);
            console.log(newWod.titles);
            console.log(newWod);

            if (this.modifyWodIndex !== -1) {
                this.wods.splice(this.modifyWodIndex, 1);
                this.modifyWodIndex = -1;
            }

            this.wods.push(newWod);
        },
        refresh() {
            this.modifyWodIndex = -1;
        },
        modifyWod(wod, index) {
            this.$refs.newWodView.wod = wod;
            this.$refs.newWodView.dialog = true;

            this.modifyWodIndex = index;
        },
        deleteWod(index) {
            this.wods.splice(index, 1);
        },
        openWod(wod) {
            this.$refs.wodDialog.wod = wod;
            this.$refs.wodDialog.wod.date = new Date(wod.date);
            this.$refs.wodDialog.wodDialog = true;
        },
        async createProgram() {
            try {
                if (this.newProgram.title !== "" && this.newProgram.type !== "" && this.newProgram.directory_id !== 0) {
                    const response = await addNewProgram(this.newProgram);
                    console.log(response.newProgramId);
                    for(let i = 0; i < this.wods.length; ++i) {
                        this.wods[i].dir = response.currentDir
                        await insertNewWod(this.wods[i], response.newProgramId);
                    }
                    this.$router.push("/programmation");
                } else {
                    this.$refs.mySnackbarRef.active("Donnees manquantes", "error");
                }
            } catch (err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        },
        refreshSelectedFolder(value) {
            this.newProgram.directory_id = value.id;
        },
    },
})
</script>

<style scoped>

</style>