<template>
    <v-row class="ma-0">
        <v-dialog v-model="dialog" width="85%">
            <v-card v-if="!loading">
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" :style="{backgroundColor: my_blue(), borderRadius: '10px'}">
                            <h2>Mes évènememts</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text style="padding: 27px">
                    <v-row>
                        <v-col cols="3">
                            <v-btn elevation="0" :style="{backgroundColor: my_blue(), borderRadius: '10px'}"><h3>Date</h3></v-btn>
                        </v-col>
                        <v-col cols="3">
                            <v-btn elevation="0" :style="{backgroundColor: my_blue(), borderRadius: '10px'}"><h3>Title</h3></v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-btn elevation="0" :style="{backgroundColor: my_blue(), borderRadius: '10px'}"><h3>Description</h3></v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(event) in progressEvents" :key="event.id"
                           :style="{
                                alignItems: 'center',
                                margin: '5px',
                                padding: 0}
                        ">
                        <v-col style="padding: 0" cols="3">
                            <h3 style="margin-left: 10px">{{ getDateWithBasicFormatDateParam(new Date(event.date)) }}</h3>
                        </v-col>
                        <v-col style="padding: 0" cols="3">
                            <h3 style="margin-left: 10px">{{ event.title }}</h3>
                        </v-col>
                        <v-col style="padding: 0" cols="4">
                            <h3 style="margin-left: 10px">{{ event.description }}</h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col style="padding: 0" cols="auto">
                            <v-btn variant="text" icon="mdi-close-box" elevation="0" style="border-radius: 5px" v-on:click="this.removeEvent(event)"></v-btn>
                        </v-col>
                    </v-row>
                    <h2 style="margin-top: 20px; margin-bottom: 10px; padding: 10px; border-radius: 10px; background-color: #E74C3C90; width: 200px ">Terminés</h2>
                    <v-row v-for="(event) in finishedEvents" :key="event.id"
                           :style="{
                                opacity: 0.5,
                                alignItems: 'center',
                                margin: '5px',
                                padding: 0}
                        ">
                        <v-col style="padding: 0" cols="3">
                            <h3 style="margin-left: 10px">{{ getDateWithBasicFormatDateParam(new Date(event.date)) }}</h3>
                        </v-col>
                        <v-col style="padding: 0" cols="3">
                            <h3 style="margin-left: 10px">{{ event.title }}</h3>
                        </v-col>
                        <v-col style="padding: 0" cols="4">
                            <h3 style="margin-left: 10px">{{ event.description }}</h3>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col style="padding: 0" cols="auto">
                            <v-btn variant="text" icon="mdi-close-box" elevation="0" style="border-radius: 5px" v-on:click="this.removeEvent(event)"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#2196f390" variant="elevated" v-on:click="this.closeDialog">
                        Fermer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {ft_isDateBefore, getDateWithBasicFormatDateParam} from "@/utils/strings";
import MySnackBar from "@/components/MySnackBar.vue";
import {mapState} from "vuex";
import {my_blue, my_green, my_purple, my_red, my_yellow} from "@/utils/enums";
import {deleteEvent, getAllEventsUser} from "@/db/events";

export default {
    name: "MyEventDialog",
    components: {MySnackBar},
    emits: ["refreshScoreWodView", "removeScore"],
    computed: {
        ...mapState(['user']),
    },
    watch: {
        async dialog(newValue) {
            if (newValue) {
                await this.handleDialogOpen()
            }
        }
    },
    data: () => ({
        dialog: false,
        events: [],
        progressEvents: [],
        finishedEvents: [],
        loading: true
    }),
    methods: {
        ft_isDateBefore,
        my_red() {
            return my_red
        },
        my_yellow() {
            return my_yellow
        },
        my_blue() {
            return my_blue
        },
        my_green() {
            return my_green
        },
        my_purple() {
            return my_purple
        },
        getDateWithBasicFormatDateParam,
        async handleDialogOpen() {
            try {
                this.events = await getAllEventsUser();
                if (this.events === null) {
                    this.loading = false;
                    return;
                }
                this.progressEvents = this.events.filter((event) => !ft_isDateBefore(new Date(event.date), new Date()))
                this.finishedEvents = this.events.filter((event) => ft_isDateBefore(new Date(event.date), new Date()))
                this.loading = false;
            } catch (err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        },
        closeDialog() {
            this.dialog = false;
        },
        async removeEvent(event) {
            const index = this.events.indexOf(event);
            if (index === -1) {
                return;
            }
            try {
                console.log(index);
                console.log(this.events[index]);
                await deleteEvent(this.events[index].id);
                this.events.splice(index, 1);
                this.progressEvents = this.events.filter((event) => !ft_isDateBefore(new Date(event.date), new Date()))
                this.finishedEvents = this.events.filter((event) => ft_isDateBefore(new Date(event.date), new Date()))
            } catch (err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
            console.log(index);
        }
    }
}
</script>

<style scoped>

</style>