<template>
    <v-row class="ma-0">
        <v-dialog v-model="eventDialog" width="50%" persistent>
            <template v-slot:activator="{ props }">
                <h5 style="width: 100%" v-bind="props">{{ this.event.title }}</h5>
            </template>
            <v-card>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                            <h2 style="padding: 5px">Évènement : {{ this.event.title }}</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="auto">
                                <h2>Titre</h2>
                                <h3 style="margin-bottom: 10px; padding: 10px; background-color: #16A08590; border-radius: 10px">{{ this.event.title }}</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="auto">
                                <h2>Description</h2>
                                <h3 style="margin-bottom: 10px; padding: 10px; background-color: #16A08590; border-radius: 10px">{{ this.event.description }}</h3>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
               <v-card-actions>
                <v-spacer></v-spacer>
                   <v-btn color="#E74C3C90" variant="elevated" v-on:click="this.removeEvent">
                       Supprimer
                   </v-btn>
                <v-btn color="#2196f390" variant="elevated" @click="this.eventDialog = false">
                    Fermer
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {mapState} from "vuex";
import MySnackBar from "@/components/MySnackBar.vue";
import {getDateWithBasicFormatDateParam} from "../utils/strings";
import {deleteEvent} from "@/db/events";

export default {
    name: "EventDialog",
    components: {MySnackBar},
    emits: ["refresh"],
    computed: {
        ...mapState(['user']),
    },
    props: {
        event: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        eventDialog: false,
    }),
    methods: {
        getDateWithBasicFormatDateParam,
        async removeEvent() {
            try {
                console.log(this.event)
                await deleteEvent(this.event.id);
                this.eventDialog = false;
                this.$emit("refresh");
            } catch (err) {
                console.error(err);
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
            }
        }
    }
}
</script>

<style scoped>

</style>