<template>
    <div>
        <v-card>
            <v-layout>
                <v-navigation-drawer permanent>
                    <v-col cols="auto">
                        <BackButton></BackButton>
                    </v-col>
                    <h3 style="margin: 10px; padding: 10px; background-color: #16A08590; border-radius: 10px">Mes programmations</h3>
                    <v-list style="margin: 10px">
                        <div v-for="program in this.allPrograms" :key="program.id">
                            <v-list-item v-if="program.state !== 'Terminée'" :style="{borderRadius: '10px', textDecoration: program.state === 'Terminée' ? 'line-through' : '' }"
                                         v-on:click="goToSpecificProgram(program)"
                                         :title="program.title"></v-list-item>
                        </div>
                    </v-list>
                    <div v-if="!this.allPrograms || this.allPrograms.length === 0">
                        <h3 style="opacity: 0.7; text-align: center">Aucune programmation</h3>
                    </div>
                    <h4 style="margin: 10px; padding: 10px; background-color: #E74C3C90; border-radius: 10px">Terminées</h4>
                    <v-list style="margin: 10px">
                        <div v-for="program in this.allPrograms" :key="program.id">
                            <v-list-item v-if="program.state === 'Terminée'" :style="{borderRadius: '10px', textDecoration: program.state === 'Terminée' ? 'line-through' : '' }"
                                         v-on:click="goToSpecificProgram(program)"
                                         :title="program.title"></v-list-item>
                        </div>
                    </v-list>
                    <template v-slot:append>
                        <div class="pa-2" style="flex-grow: 0">
                            <v-btn style="margin-top: 10px; background-color: #F1C40F90" prepend-icon="mdi-plus" v-on:click="this.$router.push('/new-program')">Ajouter</v-btn>
                        </div>
                    </template>
                </v-navigation-drawer>
                <v-main style="height: auto; min-height: 100vh">
                    <div v-if="this.loading === false">
                        <v-row style="margin: 10px">
                            <v-col cols="auto">
                                <h1>{{ this.currentProgram.title }}</h1>
                                <v-row>
                                    <v-col cols="auto">
                                        <h4 style="opacity: 0.6">{{ getDateWithBasicFormatDateParam(new Date(this.currentProgram.startDate)) }}</h4>
                                    </v-col>
                                    <v-col cols="auto">
                                        <h4 style="opacity: 0.6">-</h4>
                                    </v-col>
                                    <v-col cols="auto">
                                        <h4 style="opacity: 0.6">{{ getDateWithBasicFormatDateParam(new Date(this.currentProgram.endDate)) }}</h4>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-btn prepend-icon="mdi-clipboard-list" style="background-color: #F1C40F90; padding: 10px; border-radius: 10px">{{ this.currentProgram.type }}</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn prepend-icon="mdi-clock" :style="{backgroundColor: this.getStateColor(), padding: '10px', borderRadius: '10px'}">{{ this.currentProgram.state }}</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn prepend-icon="mdi-clock" style="background-color: #9B59B690; padding: 10px; border-radius: 10px">{{ subtractDates(this.currentProgram.startDate, this.currentProgram.endDate) }} jours restants</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-menu>
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" size="36" style="opacity: 0.5; border-radius: 3px; padding: 0; margin: 0" icon="mdi-dots-vertical"></v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item :key="0">
                                            <ModifyProgram :program="this.currentProgram"></ModifyProgram>
                                        </v-list-item>
                                        <v-list-item :key="1">
                                            <v-btn size="small" :prepend-icon="items[1].logo" v-on:click="this.deleteProgram">{{ items[1].title}}</v-btn>
                                        </v-list-item>
                                        <v-list-item :key="2">
                                            <v-btn size="small" :prepend-icon="items[2].logo" v-on:click="this.openFolder">{{ items[2].title}}</v-btn>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row style="margin: 20px 20px 30px;">
                            <v-col>
                                <h2>Description</h2>
                                <h4 style="padding: 10px; background-color: #2196f390; border-radius: 10px">{{ this.currentProgram.description }}</h4>
                            </v-col>
                        </v-row>
                        <div>
                            <v-row style="margin-left: 10px; margin-right: 10px; padding: 0">
                                <v-col cols="auto">
                                    <h2>Prochains WODs</h2>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <NewWodView @refresh="this.refresh" ref="newWodView" :current-dir="cloud.currentDirectory.name" :program="this.currentProgram.id"></NewWodView>
                                </v-col>
                            </v-row>
                            <v-row style="justify-content: space-evenly">
                                <div v-if="!this.nextWods || this.nextWods.length === 0">
                                    <h3 style="opacity: 0.7">Aucun prochain WOD</h3>
                                </div>
                               <v-col
                                   :class="'my-card-' + index"
                                   cols="auto"
                                   v-for="(wod, index) in this.nextWods"
                                   :key="wod.id"
                                   :style="{
                                       margin: '20px',
                                       backgroundColor: index < maxCountNextWods ? isEqualDate(new Date(wod.date), new Date()) ? '#16A08590' : '#2196f360' : null,
                                       borderRadius: '10px'
                                   }">
                                   <v-row style="justify-content: center">
                                       <v-col style="margin-left: 10px" cols="auto">
                                           <h1 v-if="index < maxCountNextWods" style="text-align: center">{{ formatDate(new Date(wod.date)) }}</h1>
                                       </v-col>
                                       <v-spacer></v-spacer>
                                       <v-col cols="auto">
                                           <v-btn variant="tonal" v-on:click="this.showWodDetails(wod)">Ouvrir</v-btn>
                                       </v-col>
                                   </v-row>
                                   <WodCard v-if="index < maxCountNextWods" :props-wod="wod"></WodCard>
                               </v-col>
                            </v-row>
                        </div>
                        <div style="margin: 10px">
                            <h2 style="margin-left: 10px; margin-top: 20px">Calendrier</h2>
                            <WeekCalendar :profile-id="this.profileId" :program-id="this.currentProgram.id" :key="this.currentProgram.id"></WeekCalendar>
                            <v-col cols="auto">
                                <v-btn prepend-icon="mdi-calendar-month" variant="tonal" v-on:click="this.$router.push({ name: 'Calendar', query: { profileId: this.profileId, programId: this.currentProgram.id }});"><h3>Voir calendrier mensuel</h3></v-btn>
                            </v-col>
                        </div>
                        <ListWodsWithLimit :key="this.currentProgram.id" :get-wods="this.getWodsWithLimit"></ListWodsWithLimit>
                    </div>
                    <v-row v-else style="height: 100vh; justify-content: center; align-items: center">
                        <v-col cols="12" class="text-center align-center">
                            <h1 style="opacity: 0.7">Aucune programmation sélectionnée</h1>
                        </v-col>
                    </v-row>
                </v-main>
            </v-layout>
        </v-card>
    </div>
    <MySnackBar ref="mySnackBarRef"></MySnackBar>
    <WodDialog ref="wodDialog"></WodDialog>
</template>

<script>
   import WeekCalendar from "@/components/WeekCalendar.vue";
   import MySnackBar from "@/components/MySnackBar.vue";
   import ListWodsWithLimit from "@/components/ListWodsWithLimit.vue";
   import {mapState} from "vuex";
   import BackButton from "@/components/BackButton.vue";
   import {my_red, my_yellow} from "@/utils/enums";
   import WodCard from "@/components/WodCard.vue";
   import {
       formatDate,
       ft_isDateBefore,
       getDateWithBasicFormatDateParam,
       isEqualDate,
       subtractDates
   } from "@/utils/strings";
   import WodDialog from "@/components/WodDialog.vue";
   import NewWodView from "@/views/NewWodView.vue";
   import {deleteProgram, getAllPrograms, getNextWodsInProgram, getWodsWithLimitInProgram} from "@/db/programs";
   import ModifyProgram from "@/components/ModifyProgram.vue";

   export default {
       name: "ProgramView",
       components: {
           ModifyProgram,
           NewWodView, WodDialog, WodCard, BackButton, ListWodsWithLimit, MySnackBar, WeekCalendar},
       computed: {
           ...mapState(['home', 'cloud']),
       },
       beforeMount() {
           this.profileId = localStorage.getItem('profileId');
       },
       data: () => ({
           eventDialog: false,
           profileId: "",
           wods: [],
           nextWods: [],
           maxCountNextWods: 3,
           items: [
               {title : "Modifier", logo: "mdi-file-edit"},
               {title : "Supprimer", logo: "mdi-trash-can"},
               {title : "Ouvrir le dossier", logo: "mdi-folder-move"},
           ],
           states: [
               {title: "Pas commencée", color: "#16A08590"},
               {title: "En cours", color: "#F1C40F90"},
               {title: "Terminée", color: "#E74C3C90"},
           ],
           allPrograms: [],
           currentProgram: {
               id: -1,
               name: "",
               title: "",
               type: -1,
               state: "Pas commencée",
               description: "",
               startDate: "",
               endDate: "",
               directory_id: 77,
           },
           loading: true,
       }),
       async created() {
          try {
              await this.refreshAllPrograms()
              console.log(this.wods);
              console.log(this.allPrograms);
          } catch (err) {
              console.error(err)
              this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
          }
       },
       methods: {
           subtractDates,
           my_red() {
               return my_red
           },
           isEqualDate,
           formatDate,
           getDateWithBasicFormatDateParam,
           my_yellow() {
               return my_yellow
           },
           async refresh() {
               try {
                   this.wods = [];
                   this.nextWods = [];
                   this.wods = await this.getWodsWithLimit();
                   this.nextWods = await this.getNextWodsInProgram();
               } catch (err) {
                   console.error(err);
                   this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
               }
           },
           async refreshAllPrograms() {
               try {
                   this.allPrograms = await this.getAllPrograms()
                   if (this.allPrograms === null) {
                       return;
                   }
                   for (let i = 0; i < this.allPrograms.length; ++i) {
                       this.calculState(this.allPrograms[i]);
                       console.log(this.allPrograms[i])
                       if (this.allPrograms[i].state !== "Terminée" && this.currentProgram.id === -1) {
                           this.currentProgram = this.allPrograms[i]
                       }
                   }

                   if (this.allPrograms && this.allPrograms.length > 0) {
                       this.wods = await this.getWodsWithLimit();
                       this.nextWods = await this.getNextWodsInProgram();

                       console.log(this.currentProgram.state);
                       this.loading = false;
                   }
                   else
                   {
                       this.currentProgram.id = -1;
                   }
               } catch(err) {
                   console.error(err);
                   this.$refs.mySnackBarRef.active("Erreur de connexion", "error")
               }
           },
           async goToSpecificProgram(program) {
               this.loading = true;
               this.currentProgram = program;
               this.calculState(this.currentProgram);
               this.loading = false;
               console.log(this.currentProgram);

               try {
                   await this.refresh();
               } catch (err) {
                   console.error(err)
                   this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
               }
           },
           async getWodsWithLimit() {
               try {
                   return await getWodsWithLimitInProgram(this.currentProgram.id, this.home.elementCountPerPage);
               } catch (err) {
                   this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                   console.error(err);
                   return [];
               }
           },
           async getNextWodsInProgram() {
               try {
                   return await getNextWodsInProgram(this.currentProgram.id);
               } catch (err) {
                   this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                   console.error(err);
                   return [];
               }
           },
           async getAllPrograms() {
               try {
                   return await getAllPrograms();
               } catch (err) {
                   this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                   console.error(err);
                   return [];
               }
           },
           showWodDetails(wod) {
               console.log(wod);
               this.$refs.wodDialog.wodId = wod.id;
               this.$refs.wodDialog.wodDialog = true;
           },
           getStateColor() {
               for(let i = 0; i < this.states.length; ++i) {
                   if (this.currentProgram.state.toLowerCase() === this.states[i].title.toLowerCase()) {
                       return this.states[i].color;
                   }
               }
               return this.states[2].color;
           },
           async deleteProgram() {
               try {
                   await deleteProgram(this.currentProgram.id);
                   await this.refreshAllPrograms();
               } catch (err) {
                   console.error(err);
                   this.$refs.mySnackBarRef.active("Erreur de connexion", "error");
               }
           },
           calculState(program) {
               const now = new Date();
               if (ft_isDateBefore(new Date(program.endDate), now)) {
                   program.state = "Terminée";
               }
               else if (ft_isDateBefore(new Date(program.startDate), now)) {
                   program.state = "En cours";
               }
               else {
                   program.state = "Pas commencée";
               }
               console.log(new Date(program.endDate));
               console.log(ft_isDateBefore(new Date(program.endDate), now));
           },
           openFolder() {
               try {
                   this.$router.push(`/wods/${this.currentProgram.directoryId}`);
               } catch (err) {
                   console.error(err);
                   this.$refs.mySnackBarRef.active("Erreur de connexion", "error")
               }
           }
       }
   }
</script>

<style>
.my-card-0 {
    opacity: 1;
}
.my-card-1 {
    opacity: 0.75;
}
.my-card-2 {
    opacity: 0.5;
}
.my-card-0:hover {
    opacity: 1.0;
}
.my-card-1:hover {
    opacity: 1.0;
}
.my-card-2:hover {
    opacity: 1.0;
}
</style>