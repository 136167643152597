<template>
    <v-btn
            color="#2196f390"
            prepend-icon="mdi-arrow-left"
            v-on:click="this.backPage"
    >
        Retour
    </v-btn>
</template>

<script>
export default {
    name: "BackButton",
    props: {
        callback: {
            type: Function,
            required: false
        }
    },
    methods: {
        backPage() {
            if (this.callback) {
                this.callback()
            }
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>

</style>