<template>
    <v-row class="ma-0">
        <v-dialog v-model="wodDialog" height="75%" width="50%">
            <v-progress-circular v-if="loading"></v-progress-circular>
            <v-card v-else>
                <v-card-title style="margin-left: 10px; margin-top: 10px">
                    <v-row style="padding: 15px">
                        <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                            <h2 style="padding: 5px">{{ this.wod.name.toUpperCase() }}</h2>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-subtitle style="margin-left: 10px; margin-top: 10px">
                    <h3 style="margin-left: 5px">{{ getDateWithBasicFormatDateParam(this.wod.date) }}</h3>
                </v-card-subtitle>
                <v-row style="justify-content: space-between; margin-left: 10px; margin-top: 10px; margin-right: 10px">
                    <v-col cols="auto">
                        <v-btn
                            :style="{backgroundColor: my_blue(), borderRadius: '10px', padding: '10px'}">
                            <h4>{{ this.wod.type }}</h4>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <ScoreWodDialog v-if="this.wod.name" :active-by-button="false" :wod="this.wod" :color-score-button="my_blue()"></ScoreWodDialog>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-container>
                        <v-row v-for="(item, index) in this.wodFlow" :key="index">
                            <v-col cols="auto">
                                <h3 v-if="item.type === 'title'"
                                    :style="{
                                paddingLeft: '15px',
                                paddingBottom: '5px',
                                paddingTop: '10px',
                                paddingRight: '15px',
                                backgroundColor: '#16A08590',
                                borderRadius: '10px',
                                marginTop: '10px',
                                marginLeft: item.style.indent * 3 + 'px',
                                textAlign: 'center',
                            }">{{ item.text.toUpperCase() }}
                                </h3>
                               <v-row v-else style="padding-top: 10px; padding-bottom: 20px">
                                   <v-col cols="auto" class="divider-column-1">
                                       <div class="vertical-divider-1"></div>
                                   </v-col>
                                   <h4 :style="{marginLeft: item.style.indent * 3 + 'px'}"
                                       v-html="item.text">
                                   </h4>
                               </v-row>
                            </v-col>
                        </v-row>
                        <div style="margin-top: 30px" v-if="this.wod.note.length > 0">
                            <v-row style="margin-top: 20px; border-radius: 10px">
                                <h3 style="background-color: #E74C3C90; padding: 10px; border-radius: 10px">Note</h3>
                            </v-row>
                            <v-row style="margin-top: 20px; padding: 10px; border-radius: 10px">
                                <v-col cols="auto" class="divider-column-1">
                                    <div class="vertical-divider-2"></div>
                                </v-col>
                                <h4 v-html="this.wod.note"></h4>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {mapState} from "vuex";
import MySnackBar from "@/components/MySnackBar.vue";
import {deleteWod, getWodById} from "@/db/wods";
import {getDateWithBasicFormatDateParam} from "../utils/strings";
import ScoreWodDialog from "@/components/ScoreWodDialog.vue";
import {my_blue, my_red} from "@/utils/enums";

export default {
    name: "WodDialog",
    components: {ScoreWodDialog, MySnackBar},
    emits: ["refresh"],
    computed: {
        ...mapState(['user']),
    },
    watch: {
        async wodDialog(newValue) {
            if (newValue && this.wod.name === "") {
                await this.handleDialogOpen()
                this.loading = false;
            } else if (newValue && this.wod.name !== "") {
                this.wod.date = new Date(this.wod.date);
                this.createWodFlow();
                console.log(this.wodFlow);

                this.possibleToDelete = false;
                this.loading = false;
            }
            else {
                this.reset();
            }
            console.log(newValue);
        },
    },
    data: () => ({
        wodDialog: false,
        wod: {
            name: "",
            type: "",
            dir: "",
            date: new Date(),
            titles: [],
            descriptions: [],
            scores: [],
            isPr: false,
            note: "",
        },
        wodFlow: [],
        wodId: -1,
        loading: true,
        possibleToDelete: true,
    }),
    methods: {
        my_blue() {
            return my_blue
        },
        my_red() {
            return my_red
        },
        getDateWithBasicFormatDateParam,
        async handleDialogOpen() {
            if (this.wodId !== -1) {
                try {
                    this.wod = await getWodById(this.wodId);
                    this.wod.date = new Date(this.wod.date);
                    console.log(this.wod);
                    this.createWodFlow();
                    console.log(this.wodFlow);
                    this.loading = false;
                } catch (err) {
                    console.error(err);
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error");
                }
            } else {
                this.loading = false;
            }
        },
        createWodFlow() {
            if (this.wod) {
                const allItems = [
                    ...this.wod.descriptions.map(description => ({ ...description, type: 'description' })),
                    ...this.wod.titles.map(title => ({ ...title, type: 'title' }))
                ];
                const sortedItems = allItems.sort((a, b) => a.position - b.position);
                this.wod.note = this.wod.note.replace(/\n/g, "<br>");
                this.wodFlow = sortedItems.map(item => {
                    item.text = item.text.replace(/\n/g, "<br>")
                    return item;
                });
            }
        },
        reset() {
            this.wod = {
                name: "",
                    type: "",
                    dir: "",
                    date: new Date(),
                    titles: [],
                    descriptions: [],
                    scores: [],
                    isPr: false,
                    note: "",
            };
            this.wodFlow = [];
            this.wodId = -1;
            this.loading = true;
        },
        async deleteWod() {
            try {
                if (this.wodId !== -1)
                {
                    await deleteWod(this.wodId)
                    this.$refs.mySnackbarRef.active("Wod supprimé", "success");

                    this.wodDialog = false;
                }
                else
                {
                    this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                }
            } catch (err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
        },
    }
}
</script>

<style scoped>

.vertical-divider-1 {
    width: 7px;
    height: 100%;
    border-radius: 50px;
    background-color: #16A08590;
}
.vertical-divider-2 {
    width: 7px;
    height: 100%;
    border-radius: 50px;
    background-color: #E74C3C90;
}
.divider-column-1 {
    padding: 0;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>