export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello everybody"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookfit"])},
  "button_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
  "button_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "mail address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail address"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
  "login_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "registration_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
  "field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required"])},
  "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark mode"])},
  "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "connection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection in progress"])},
  "successful_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful connection"])},
  "connection_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection error"])},
  "missing_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing data"])},
  "registration_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration in progress"])},
  "successful_registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful registration"])},
  "registration_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during registration"])},
  "mail_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mail is already used"])},
  "too_short_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain at least 8 characters"])},
  "invalid_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mail is invalid"])}
}