<template>
        <v-card style="max-height: 500px; padding: 20px; border-radius: 10px; overflow-y: auto">
            <v-card-title style="margin-left: 10px">
                <v-row style="padding: 15px; justify-content: center">
                    <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                        <h2 style="padding: 5px">{{ this.wod.name.toUpperCase() }}</h2>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row style="justify-content: center">
                        <v-col cols="auto">
                            <v-btn
                                :style="{backgroundColor: my_blue(), borderRadius: '10px', padding: '10px'}">
                                <h4>{{ this.wod.type }}</h4>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in this.wodFlow" :key="index" style="justify-content: center">
                        <v-col cols="auto">
                            <h3 v-if="item.type === 'title'"
                                :style="{
                            paddingLeft: '15px',
                            paddingBottom: '5px',
                            paddingTop: '10px',
                            paddingRight: '15px',
                            backgroundColor: '#16A08590',
                            borderRadius: '10px',
                            marginTop: '10px',
                            marginLeft: item.style.indent * 3 + 'px',
                            textAlign: 'center'
                        }"
                            >{{ item.text.toUpperCase() }}</h3>
                            <h3 v-else
                                :style="{padding: '10px,',
                            marginLeft: item.style.indent * 3 + 'px',
                            textAlign: 'center'
                        }"
                                v-html="item.text">
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row style="margin-top: 20px; background-color: #E74C3C90; border-radius: 10px">
                        <v-col>
                            <h3 style="text-align: center" v-html="this.wod.note"></h3>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>

import {mapState} from "vuex";
import MySnackBar from "@/components/MySnackBar.vue";
import {getDateWithBasicFormatDateParam} from "../utils/strings";
import {my_blue, my_red} from "@/utils/enums";
import {getWodById} from "@/db/wods";

export default {
    name: "WodCard",
    components: {MySnackBar},
    emits: ["refresh"],
    computed: {
        ...mapState(['user']),
    },
    watch: {
        async wodDialog(newValue) {
            if (newValue) {
                await this.handleDialogOpen()
                this.loading = false;
            }
            else {
                this.reset();
            }
            console.log(newValue);
        },
    },
    props: {
        propsWod: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        wodDialog: false,
        wod: {
            name: "",
            type: "",
            dir: "",
            date: new Date(),
            titles: [],
            descriptions: [],
            scores: [],
            isPr: false,
            note: "",
        },
        wodFlow: [],
    }),
    async created() {
        this.wod = await getWodById(this.propsWod.id);
        this.wod.date = new Date(this.propsWod.date);

        this.createWodFlow();
    },
    methods: {
        my_blue() {
            return my_blue
        },
        my_red() {
            return my_red
        },
        getDateWithBasicFormatDateParam,
        createWodFlow() {
            if (this.wod) {
                const allItems = [
                    ...this.wod.descriptions.map(description => ({ ...description, type: 'description' })),
                    ...this.wod.titles.map(title => ({ ...title, type: 'title' }))
                ];
                const sortedItems = allItems.sort((a, b) => a.position - b.position);
                this.wod.note = this.wod.note.replace(/\n/g, "<br>");
                this.wodFlow = sortedItems.map(item => {
                    item.text = item.text.replace(/\n/g, "<br>")
                    return item;
                });
            }
        },
        reset() {
            this.wod = {
                name: "",
                    type: "",
                    dir: "",
                    date: new Date(),
                    titles: [],
                    descriptions: [],
                    scores: [],
                    isPr: false,
                    note: "",
            };
            this.wodFlow = [];
        }
    }
}
</script>

<style scoped>

</style>