import { createStore } from 'vuex';
import {getPrevMondayFromDate} from "@/utils/strings";

const store = createStore({
    state: {
        cloud: {
            currentDirectory: {
                parent_name: ".",
                name: '.',
                indent: 0,
            },
            displayResultDepthSearch: false,
            showFilters: false,
            searchObject: {
                wodTypes: [],
                dates: [],
            },
            searchField: "",
            searchFilters: []
        },
        home: {
            currentDate: getPrevMondayFromDate(new Date()),
            elementCountPerPage: 3,
        },
    },
    mutations: {
        setCloud(state, cloud) {
            state.cloud = cloud;
        },
        resetCloud(state) {
            state.cloud = {
                currentDirectory: {
                    parent_name: ".",
                    name: '.',
                    indent: 0,
                },
                displayResultDepthSearch: false,
                showFilters: false,
                searchObject: {
                    wodTypes: [],
                    dates: [],
                },
                searchField: "",
                searchFilters: []
            }
        },
        resetSearchCloud(state) {
            state.cloud = {
                currentDirectory: state.cloud.currentDirectory,
                displayResultDepthSearch: false,
                showFilters: false,
                searchObject: {
                    wodTypes: [],
                    dates: [],
                },
                searchField: "",
                searchFilters: []
            }
        },
        setCurrentDate(state, newDate) {
            state.home = newDate;
        },
        setElementCountPerPage(state, count) {
            state.home = count;
        },
    },
    actions: {
        // Vos actions
    },
    getters: {
        // Vos getters
    },
});

export default store;