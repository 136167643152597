<template>
    <v-dialog v-model="dialog" width="1024">
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" size="small" prepend-icon="mdi-file-edit">Modifier</v-btn>
        </template>
        <v-card>
            <v-card-title style="margin-left: 10px; margin-top: 10px">
                <v-row style="padding: 15px">
                    <v-col cols="auto" style="background-color: #F1C40F90; border-radius: 10px">
                        <h2>Modification de la programmation {{ this.newProgram.title }}</h2>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <h2 style="margin-bottom: 10px">Titre</h2>
                    <v-text-field
                        label="Titre"
                        v-model="this.newProgram.title"
                        density="compact"
                        hide-details
                        single-line
                        style="margin-bottom: 20px; margin-left: 10px"
                    ></v-text-field>
                    <h2 style="margin-bottom: 10px">Description</h2>
                    <v-row style="margin-bottom: 10px">
                        <v-col>
                            <v-textarea
                                label="Description"
                                v-model="this.newProgram.description"
                                density="compact"
                                hide-details
                                rows="3"
                                style="margin-bottom: 10px; margin-left: 10px"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                       <v-col cols="auto">
                           <h2>Date de début</h2><VueDatePicker style="margin-top: 10px;" v-model="this.newProgram.startDate" :enable-time-picker="false" :format="format"/>
                       </v-col>
                       <v-col v-if="checkboxEndDateEnabled" cols="auto">
                           <h2>Date de fin</h2><VueDatePicker style="margin-top: 10px;" v-model="this.newProgram.endDate" :enable-time-picker="false" :format="format"/>
                       </v-col>
                   </v-row>
                   <v-row style="margin-left: 10px">
                       <v-checkbox v-model="checkboxEndDateEnabled" label="Modifier la date de fin"></v-checkbox>
                   </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#2196f390" variant="elevated" @click="dialog = false">
                    Fermer
                </v-btn>
                <v-btn color="#F1C40F90" variant="elevated" @click="dialog = false" v-on:click="modifyProgram">
                    Modifier
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
</template>

<script>
import {my_green, my_yellow} from "@/utils/enums";
import VueDatePicker from "@vuepic/vue-datepicker";
import MySnackBar from "@/components/MySnackBar.vue";

export default {
    name: "ModifyProgram",
    components: {MySnackBar, VueDatePicker},
    emits:['addScoreInCurrentWod'],
    props: {
        program: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.newProgram.title = this.program.title;
        this.newProgram.description = this.program.description;
        this.newProgram.startDate = this.program.startDate;
        this.newProgram.endDate = this.program.endDate;
    },
    methods: {
        my_green() {
            return my_green
        },
        my_yellow() {
            return my_yellow
        },
        async modifyProgram() {
            try {
                console.log(this.newProgram.title);
                console.log(this.newProgram.description);
                console.log(this.newProgram.startDate);
                console.log(this.newProgram.endDate);
                //this.$emit("addScoreInCurrentWod", this.newScore);
            } catch (err) {
                console.error(err)
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
            }
            this.newProgram.title = '';
            this.newProgram.description = '';
            this.newProgram.startDate= new Date();
            this.newProgram.endDate = new Date();
        }
    },
    data: () => ({
        dialog: false,
        checkboxEndDateEnabled: false,
        newProgram: {
            title: "",
            description: "",
            startDate: new Date(),
            endDate: new Date()
        },
        format: (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
    }),
}
</script>

<style>
.scrollable-list {
    max-height: 250px;
    overflow-y: auto;
}
</style>
