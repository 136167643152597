<template>
    <v-layout>
        <v-main>
            <v-container fluid>
                <v-row style="margin-left: 20px; margin-right: 20px; margin-bottom: 0 ; align-items: center;">
                    <v-col cols="auto">
                        <BackButton></BackButton>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn :style="{backgroundColor: '#34495E90'}" prepend-icon="mdi-plus-box" v-on:click="this.addEvent">Évènement</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <h2>{{ getBasicDateFormatWithoutDay(this.currentDate) }}</h2>
                    <v-btn style="margin-right: 10px; margin-left: 20px; background-color: #2196f390; border-radius: 5px" variant="elevated" elevation="0" v-on:click="this.today">Aujourd'hui</v-btn>
                    <v-col cols="auto">
                        <v-icon class="chevrons" icon="mdi-chevron-left" v-on:click="this.prevMonth"></v-icon>
                    </v-col>
                    <v-col cols="auto">
                        <v-icon class="chevrons" icon="mdi-chevron-right" v-on:click="this.nextMonth"></v-icon>
                    </v-col>
                </v-row>
                <v-container v-if="!this.loading" fluid>
                    <v-row v-for="(row, rowIndex) in 6" :key="rowIndex" class="rows-calendar">
                        <v-col v-for="(card, colIndex) in 7" :key="colIndex" :class="this.getColumnClass(rowIndex, colIndex)">
                            <h3 v-if="rowIndex === 0" style="background-color: #16A08590; text-align: center; border-radius: 10px">{{ dayOnWeek()[colIndex] }}</h3>
                            <h3 v-if="this.checkIfIsCurrentMonth(rowIndex, colIndex)" style="text-align: center; margin-top: 5px">{{ this.getDayOfMonth(rowIndex, colIndex).getDate() }}</h3>
                            <v-main style="overflow-y: auto; max-height: 9vh">
                               <div v-if="this.checkIfIsCurrentMonth(rowIndex, colIndex)">
                                   <div v-for="(wod, index) in this.getWodsOnSpecificDay(rowIndex, colIndex)" :key="index">
                                       <v-container style="padding: 1px 0 1px 10px; margin-bottom: 2px; background-color: #F1C40F90; border-radius: 10px">
                                           <h5 v-on:click="this.showWodDetails(wod)">{{ wod.name }}</h5>
                                       </v-container>
                                   </div>
                                   <div v-for="(pr, index) in this.getPRsOnSpecificDay(rowIndex, colIndex)" :key="index">
                                       <v-container :style="{padding: '1px 0 1px 10px', marginBottom: '2px', backgroundColor: pr.color, borderRadius: '10px'}">
                                           <SpecificPRDialog :pr="pr" :active-by-button="false"></SpecificPRDialog>
                                       </v-container>
                                   </div>
                                   <div v-for="(event, index) in this.getEventOnSpecificDay(rowIndex, colIndex)" :key="index">
                                       <v-container :style="{padding: '1px 0 1px 10px', marginBottom: '2px', backgroundColor: '#34495E90', borderRadius: '10px'}">
                                           <EventDialog :event="event" @refresh="this.refresh"></EventDialog>
                                       </v-container>
                                   </div>
                               </div>
                            </v-main>
                        </v-col>
                    </v-row>
                </v-container>
                <div v-else>
                    <MyProgressCircular></MyProgressCircular>
                </div>
            </v-container>
        </v-main>
    </v-layout>
    <MySnackBar ref="mySnackbarRef"></MySnackBar>
    <WodDialog ref="wodDialog" :wod-id="this.wodId"></WodDialog>
    <NewEventDialog ref="eventDialog" @refresh="this.refresh" :program-id="this.programId"></NewEventDialog>
</template>

<script>
import {dayOnWeek, getBasicDateFormatWithoutDay} from "../utils/enums";
import {getDateWithBasicFormatWithoutDay, isEqualDate} from "../utils/strings";
import {getWodsOnSpecificMonth} from "@/db/wods";
import BackButton from "@/components/BackButton.vue";
import {mapState} from "vuex";
import SpecificPRDialog from "@/components/SpecificPRDialog.vue";
import {GetPRsOnCurrentMonth} from "@/db/profile";
import MySnackBar from "@/components/MySnackBar.vue";
import WodDialog from "@/components/WodDialog.vue";
import NewEventDialog from "@/components/NewEventDialog.vue";
import {getEventsOnSpecificMonth} from "@/db/events";
import EventDialog from "@/components/EventDialog.vue";
import {getAllEventsInProgram, getAllWodsInProgram} from "@/db/programs";
import MyProgressCircular from "@/components/MyProgressCircular.vue";

export default {
    name: "MyCalendar",
    components: {MyProgressCircular, EventDialog, NewEventDialog, WodDialog, MySnackBar, SpecificPRDialog, BackButton},
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        currentDate: new Date,
        wodsOnCurrentMonth: [],
        prsOnCurrentMonth: [],
        eventsOnCurrentMonth: [],
        myProfileId: "",
        wodId: -1,

        profileId: -1,
        programId: -1,

        loading: true,
    }),
    async beforeMount()
    {
        this.profileId = this.$route.query.profileId;
        this.programId = this.$route.query.programId;
        this.myProfileId = localStorage.getItem('profileId');
        try {
            await this.fillWodsInCurrentMonth();
            if (this.programId === "-1") {
                await this.fillPRsOnCurrentMonth();
            }
            await this.fillEventsOnCurrentMonth();
        } catch (err) {
            console.error(err)
        }
    },
    methods: {
        dayOnWeek() {
            return dayOnWeek
        },
        async fillWodsInCurrentMonth() {
            try {
                this.loading = true;
                console.log(this.programId)
                if (this.programId === "-1") {
                    this.wodsOnCurrentMonth = await getWodsOnSpecificMonth(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.profileId);
                } else {
                    this.wodsOnCurrentMonth = await getAllWodsInProgram(this.programId);
                    console.log(this.wodsOnCurrentMonth);
                }
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        async fillPRsOnCurrentMonth() {
            try {
                this.loading = true;
                this.prsOnCurrentMonth = await GetPRsOnCurrentMonth(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.profileId)
                console.log(this.prsOnCurrentMonth);
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        async fillEventsOnCurrentMonth() {
            try {
                this.loading = true;
                if (this.programId === "-1") {
                    this.eventsOnCurrentMonth = await getEventsOnSpecificMonth(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.profileId)
                } else {
                    this.eventsOnCurrentMonth = await getAllEventsInProgram(this.programId);
                }
                console.log(this.eventsOnCurrentMonth);
                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err)
            }
        },
        getBasicDateFormatWithoutDay, getDateWithBasicFormatWithoutDay,
        getDayOfMonth(rowIndex, colIndex) {
            const firstDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 0);
            const firstDayOfWeek = firstDayOfMonth.getDay(); // Jour de la semaine du premier jour du mois (0 pour dimanche, 1 pour lundi, etc.)
            const dayOfMonth = rowIndex * 7 + colIndex - firstDayOfWeek + 1; // Calcul du jour du mois en fonction de l'index de la ligne et de la colonne

            const currentMonth = this.currentDate.getMonth();
            const currentYear = this.currentDate.getFullYear();
            const displayedDate = new Date(currentYear, currentMonth, 0);
            displayedDate.setDate(displayedDate.getDate() + dayOfMonth);

            return displayedDate;
        },
        checkIfIsToday(rowIndex, colIndex) {
            const firstDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 0);
            const firstDayOfWeek = firstDayOfMonth.getDay(); // Jour de la semaine du premier jour du mois (0 pour dimanche, 1 pour lundi, etc.)
            const dayOfMonth = rowIndex * 7 + colIndex - firstDayOfWeek + 1; // Calcul du jour du mois en fonction de l'index de la ligne et de la colonne

            const currentMonth = this.currentDate.getMonth();
            const currentYear = this.currentDate.getFullYear();
            const displayedDate = new Date(currentYear, currentMonth, 0);
            displayedDate.setDate(displayedDate.getDate() + dayOfMonth);

            const today = new Date();
            return !!isEqualDate(displayedDate, today);

        },
        checkIfIsCurrentMonth(rowIndex, colIndex) {
            const firstDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 0);
            const firstDayOfWeek = firstDayOfMonth.getDay(); // Jour de la semaine du premier jour du mois (0 pour dimanche, 1 pour lundi, etc.)
            const dayOfMonth = rowIndex * 7 + colIndex - firstDayOfWeek + 1; // Calcul du jour du mois en fonction de l'index de la ligne et de la colonne

            const currentMonth = this.currentDate.getMonth();
            const currentYear = this.currentDate.getFullYear();
            const displayedDate = new Date(currentYear, currentMonth, 0);
            displayedDate.setDate(displayedDate.getDate() + dayOfMonth);
            return displayedDate.getMonth() === currentMonth;

        },
        nextMonth() {
            const newDate = new Date(this.currentDate);
            newDate.setMonth(newDate.getMonth() + 1);
            this.currentDate = newDate;
            this.refresh();
        },
        prevMonth() {
            const newDate = new Date(this.currentDate);
            newDate.setMonth(newDate.getMonth() - 1);
            this.currentDate = newDate;
            this.refresh();
        },
        today() {
            this.currentDate = new Date();
            this.refresh();
        },
        async refresh() {
            try {
                this.loading = true;

                this.wodsOnCurrentMonth = [];
                this.prsOnCurrentMonth = [];
                this.eventsOnCurrentMonth = [];
                await this.fillWodsInCurrentMonth();
                await this.fillPRsOnCurrentMonth();
                await this.fillEventsOnCurrentMonth();

                this.loading = false;
            } catch (err) {
                this.$refs.mySnackbarRef.active("Erreur de connexion", "error")
                console.error(err);
            }
        },
        getWodsOnSpecificDay(rowIndex, colIndex) {
            const currentDay = this.getDayOfMonth(rowIndex, colIndex);
            if (!this.wodsOnCurrentMonth) {
                return [];
            }
            return this.wodsOnCurrentMonth.filter((wod) => {
                const wodDate = new Date(wod.date);
                return wodDate.getDate() === currentDay.getDate() && wodDate.getMonth() === currentDay.getMonth();
            })
        },
        getPRsOnSpecificDay(rowIndex, colIndex) {
            const currentDay = this.getDayOfMonth(rowIndex, colIndex);
            if (!this.prsOnCurrentMonth) {
                return [];
            }
            return this.prsOnCurrentMonth.filter((pr) => {
                const prDate = new Date(pr.dateCreate);
                return prDate.getDate() === currentDay.getDate() && prDate.getMonth() === currentDay.getMonth();
            })
        },
        getEventOnSpecificDay(rowIndex, colIndex) {
            const currentDay = this.getDayOfMonth(rowIndex, colIndex);
            if (!this.eventsOnCurrentMonth) {
                return [];
            }
            return this.eventsOnCurrentMonth.filter((event) => {
                const eventDate = new Date(event.date);
                return eventDate.getDate() === currentDay.getDate() && eventDate.getMonth() === currentDay.getMonth();
            })
        },
        getColumnClass(rowIndex, colIndex) {
            let style = "";
            if (rowIndex === 0) {
                style += 'first-column '
            } else {
                style += 'other-column '
            }
            if (this.checkIfIsToday(rowIndex, colIndex) && this.checkIfIsCurrentMonth(rowIndex, colIndex)) {
                style += 'columns-calendar-today';
            } else if (this.checkIfIsCurrentMonth(rowIndex, colIndex)) {
                style += 'columns-calendar'
            } else {
                style += 'columns-calendar-out-month';
            }
            return style;
        },
        showWodDetails(wod) {
            this.$refs.wodDialog.wodId = wod.id;
            this.$refs.wodDialog.wodDialog = true;
        },
        addEvent() {
            if (!this.$refs.wodDialog.wodDialog) {
                this.$refs.eventDialog.eventDialog = true;
            }
        }
    },
}
</script>

<style>
.chevrons {
    border-radius: 5px;
    background-color: #2196f390;
}
.first-column {
    min-height: 17vh;
}
.other-column {
    min-height: 13.5vh;
}
.columns-calendar {
    border: 1px solid #2196f360;
    background-color: #2196f332;
}
.columns-calendar-today {
    border: 1px solid #2196f360;
    background-color: #2196f380;
}
.columns-calendar-out-month {
    border: 1px solid #2196f350;
    background-color: #2196f315;
}
.rows-calendar {
    margin-left: 20px;
    margin-right: 20px
}

</style>