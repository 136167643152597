export function getAllFolders(folders) {
    const result = [];

    function displayChildren(parentFolder, indent) {
        const children = folders.filter(folder => folder.parent_name === parentFolder.name);

        children.forEach(child => {
            child.indent = indent * 6;
            result.push(child);

            if (child.open) {
                displayChildren(child, indent + 1);
            }
        });
    }

    const rootFolders = folders.filter(folder => folder.parent_name === '.');

    rootFolders.forEach(folder => {
        folder.indent = folder.indent * 6;
        result.push(folder);

        if (folder.open) {
            displayChildren(folder, folder.indent + 1);
        }
    });

    return result;
}

export function getFolderPath(folders, folderName) {
    const path = [];
    let currentFolder = folders.find(folder => folder.name === folderName);

    while (currentFolder) {
        path.unshift(currentFolder.name);
        const parentFolderName = currentFolder.parent_name;
        currentFolder = folders.find(folder => folder.name === parentFolderName);
    }

    return '/' + path.join('/');
}

export function getChildFolders(folders, parentFolderName) {
    return folders.filter(folder => folder.parent_name === parentFolderName);
}

export function getFolderByName(folders, folderName) {
    return folders.find(folder => folder.name === folderName);
}

export function getParentFolder(folders, folderName) {
    const folder = folders.find(folder => folder.name === folderName);
    const root = {
        parent_name: ".",
            name: '.',
            open: false,
            indent: 0,
    };
    if (folder) {
        const parentName = folder.parent_name;
        if (parentName === '.') {
            return root;
        } else {
            return folders.find(folder => folder.name === parentName);
        }
    }
    return root;
}